import { defineComponent } from 'vue';
import { AppComponent } from 'extracrew-ionic-lib';

export default defineComponent({

    extends: AppComponent,

    props: ['topic'],

    data() {
        const data : {
        } = {
        }

        return data;
    },

    created() { },

    methods: { }

});