import { defineComponent } from 'vue';
import draggable from 'vuedraggable';

export default defineComponent({

    components: { draggable },

    props: ['modelValue', 'onChange', 'group', 'setRef'],

    data: function() {
        let data : {list: any[]} = {
            list: []
        };
        return data;
    },

    created() {
        // This is necessary to avoid Vue warning about mutating a prop.
        if (this.modelValue) {
            for(let i of this.modelValue) {
                this.list.push(i);
            }
        }
    },

    mounted() {
        if (this.setRef) {
            this.setRef(this);
        }
    },

    methods: { 

        emit() {
            this.$emit('update:modelValue', this.list);
            this.$emit('change', this.list);
                
            if (this.onChange) {
                this.onChange(this.list);
            }
        },

        manageDragging() {
            setTimeout(() => {
                this.emit();
            }, 5);
        },

        addItem(item: any) {
            this.list.push(item);
            this.emit();
        },

        removeItem(position: number) {
            this.list.splice(position, 1);
            this.emit();
        }

    }

});