import { defineComponent } from 'vue';
import { AppComponent } from 'extracrew-ionic-lib';

export default defineComponent({

    props: ['user'],

    extends: AppComponent,

    methods: {
        openProfile() {
            this.to('/profile/' + this.user.ID);
        }
    }

});