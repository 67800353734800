import { defineComponent } from 'vue';
import { AppComponent, SafeArea, FormInput, EventListener } from 'extracrew-ionic-lib';
import Exam from '@/models/Exam';

export default defineComponent({

    name: 'exam',
    extends: AppComponent,

    components: {
        SafeArea,
        FormInput
    },

    data() {
        const data : {
            exam: Exam|null,
            step: number,
            result: any
        } = {
            exam: null,
            step: 0,
            result: null
        }

        return data;
    },

    created() {
        this.loadExam();
    },

    methods: {

        loadExam() {
            const id : string = this.$route.params.id as string;
            Exam.get(id)
            .then(e => {
                this.exam = e;
            })
        },

        repeat() {
            this.step = 0;
            this.exam = null;
            this.result = null;
            this.loadExam();
        },

        prevStep() {
            if (this.step <= 0) {
                return;
            }
            this.step -= 1;
        },
    
        nextStep() {
            this.step += 1;
        },
 
        finishAircompanyQuiz() {
    
            this.exam!.validate()
            .then(() => {
                this.step += 1;
            });
    
        },
    
        validate() {

            this.step += 1;
    
            this.exam!.validate()
            .then(response => {
                this.result = response;
                EventListener.trigger('exams');
            });
    
        },

        scoreClass() {
            if (!this.result) return {}

            if (this.result.score >= 1) {
                return {perfect: true};
            }
            else if (this.result.score >= 0.7) {
                return {excelent: true}
            }
            else if (this.result.score >= 0.5) {
                return {good: true}
            }
            else {
                return {bad: true};
            }
        }

    }

});