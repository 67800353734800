import { defineComponent } from 'vue';
import { AppComponent, Carousel, Store } from 'extracrew-ionic-lib';
import Post from '@/models/Post';
import PostCard from '@/components/post-card/post-card.vue';

export default defineComponent({

    extends: AppComponent,

    components: {
        Carousel,
        PostCard
    },

    data() {
        const data : {
            partners: Post[]|null,
            featured: Post[]|null,
            articles: Post[]|null,
            formation: Post[]|null
            banner: Post|null,
            single: Post|null
        } = {
            partners: null,
            featured: null,
            banner: null,
            articles: null,
            formation: null,
            single: null
        }

        return data;
    },

    created() {

        const setContent = (res: any) => {
            this.partners = res.partners;
            this.featured = res.featured;
            this.banner = res.banners.main;
            this.articles = res.articles;
            this.formation = res.formation;
            this.single = res.single;

            if (this.featured) {
                this.featured.splice(2, 0, res.banners.destacado);
            }
        }

        const content = Store.getSync('homeContent');
        if (content) {
            setContent(content);
        }

        Post.homeContent()
        .then((res: any) => {
            setContent(res);
            Store.set('homeContent', res);
        });

    },

    methods: { }

});