import { defineComponent } from 'vue';
import { AppComponent, SafeArea } from 'extracrew-ionic-lib';
import Post from '@/models/Post';
import Back from '@/components/back/back.vue';
import Reply from '@/models/Reply';
import UserSnippet from '@/components/user-snippet/user-snippet.vue';

export default defineComponent({

    name: 'post',
    extends: AppComponent,

    components: {
        SafeArea,
        Back,
        UserSnippet
    },

    data() {
        const data : {
            post: Post|null,
            comments: Reply[]|null,
            displayCommentForm: boolean,
            comment: string
        } = {
            post: null,
            comments: null,
            displayCommentForm: false,
            comment: ''
        }

        return data;
    },

    created() {
        Post.get(Number(this.$route.params.id))
        .then(post => {
            this.post = post;

            this.reloadComments();
        });
    },

    methods: {

        reloadComments() {
            this.post!.comments()
            .then(cms => {
                this.comments = cms;
            })
        },

        publishComment() {
            if (!this.comment) return;

            this.post?.publishComment(this.comment)
            .then(() => this.reloadComments())

            this.comment = '';
            this.displayCommentForm = false;
        }
    }

});