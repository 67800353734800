import { defineComponent } from 'vue';
import { AppComponent, SafeArea, InfiniteScroll, Utils } from 'extracrew-ionic-lib';
import Topic from '@/models/Topic';
import Forum from '@/models/Forum';
import Back from '@/components/back/back.vue';
import TopicCard from '@/components/topic-card/topic-card.vue';

export default defineComponent({

    name: 'topic',
    extends: AppComponent,

    components: {
        SafeArea,
        Back,
        InfiniteScroll,
        TopicCard
    },

    data() {
        const data : {
            topics: Topic[]|null,
            forum: Forum|null
        } = {
            topics: null,
            forum: null
        }

        return data;
    },

    created() {

        Forum.get(this.$route.params.id)
        .then(f => {
            this.forum = f;
        });

    },

    methods: {
        loadMoreTopics($e: any) {
            Topic.list(this.$route.params.id, this.topics? this.topics.length : 0)
            .then(tps => {
                this.topics = Utils.addToList(this.topics, tps);

                if ($e) {
                    $e.stop(false);
                }
            });
        }
    }

});