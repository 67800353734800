import { defineComponent } from 'vue';
import { IonContent, IonHeader, IonPage, IonFooter } from '@ionic/vue';
import { StatusBar, StatusBarInfo } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';

export default defineComponent({
    components: {
        IonContent,
        IonPage,
        IonHeader,
        IonFooter
    },

    props: ['disabled'],

    data() {
        let data : {
            overlays: boolean
            isWeb: boolean
        } = {
            overlays: false,
            isWeb: false
        };

        return data;
    },

    created() {

        this.isWeb = Capacitor.getPlatform() == 'web';

        StatusBar.getInfo()
        .then((value: StatusBarInfo) => {
            this.overlays = value.overlays ? true : false;
        });
    },

    methods: {
        logScrolling($e: any) {
            this.$emit('scroll', {detail: $e.detail, element: this.$refs.ioncontent});
        }
    }
})