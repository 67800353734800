import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = {
  key: 0,
  class: "multiselect-dropdown"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["multiselect", {open: _ctx.open, inverted: _ctx.inverted, disabled: _ctx.disabled}])
  }, [
    _createElementVNode("div", {
      class: "multiselect-bullet",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDropdown()))
    }, [
      _createElementVNode("div", {
        class: "multiselect-selected",
        innerHTML: _ctx.htmlForLabel()
      }, null, 8, _hoisted_1),
      _createVNode(_component_svg_icon, {
        name: "chevron-down",
        class: "text-primary"
      })
    ]),
    (_ctx.options)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["multiselect-option", {selected: _ctx.isSelected(option)}]),
              key: index,
              onClick: ($event: any) => (_ctx.select(option))
            }, [
              (_ctx.isSelected(option))
                ? (_openBlock(), _createBlock(_component_svg_icon, {
                    key: 0,
                    name: "check",
                    source: "heroicons-solid"
                  }))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                innerHTML: option.content ? option.content : option.value
              }, null, 8, _hoisted_4)
            ], 10, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}