
export default [
    {
        'title' : 'General',
        'questions' : [
            {
                'title' : 'Llevo años con un seguro de pérdida de licencia y no quiero cambiar de aseguradora porque perderé las bonificaciones… ¿esto es así?',
                'text' : 'Un seguro de pérdida de licencia no genera ahorro en primas por no siniestralidad. Es decir, el hecho de que usted lleve mucho tiempo en una entidad aseguradora no le aportará una disminución en la prima. El cambio a un seguro con mayor cobertura sólo puede ser una ventaja para el piloto. No hay ningún compromiso de permanencia más allá del período contratado. Las garantías expiran anualmente con la finalización del contrato.<br><br>Dicho de otro modo, no hay ninguna obligación ni ventaja adicional por seguir con un Seguro de Pérdida de Licencia que no actualice sus coberturas, o que no ofrezca una cuota más ventajosa. Debería comprobar que su seguro es más competitivo en precio y coberturas.'
            },
            {
                'title' : '¿Qué hace falta para formalizar el contrato del Seguro de Pérdida de Licencia?',
                'text' : 'A través del e-mail&nbsp;<strong><a href="mailto:agarcia@santasusana.com">extracrew@santasusana.com</a></strong>&nbsp;podrá contactar con la aseguradora. Un técnico especializado le atenderá y le guiará en los pasos a seguir para aportar la documentación necesaria para la formalización del contrato. Si lo desea también puede acudir a las oficinas en Barcelona.<br><br>El seguro estará activo una vez que la aseguradora haya recibido la documentación (licencia, certificado médico, declaración de salud y datos bancarios). Una vez haya sido estudiada por la compañía de seguros, la aseguradora le enviará un e-mail de confirmación del seguro.'
            }
        ]
    },
    {
        'title' : 'Nacionalidad y convenios',
        'questions' : [
            {
                'title' : 'Soy español y piloto con licencia JAR española, pero trabajo en la India ¿puedo contratar el Seguro de Pérdida de Licencia?',
                'text' : 'Sí. No hay ninguna restricción en cuanto al lugar donde se trabaja, mientras sea español o tenga residencia española. Un piloto extranjero también puede contratar el seguro si reúne las condiciones de contratación.'
            },
            {
                'title' : 'Soy extranjero y piloto ¿yo también puedo contratar el seguro de pérdida de licencia?',
                'text' : 'Siempre y cuando tenga Tarjeta de Residencia española, resida en España, tribute en España y tenga cuenta bancaria en España.'
            },
            {
                'title' : 'Gracias al Convenio Colectivo mi compañía paga parte de la póliza del seguro. ¿Perderé estas ayudas si contrato el seguro?',
                'text' : 'No perderá las ayudas, ya que esa aportación de la empresa es un derecho del piloto recogido en el Convenio Colectivo, independientemente de la entidad aseguradora. Usted tiene derecho a esas ayudas como el resto de sus compañeros.'
            },
            {
                'title' : '¿Quién puede ser el tomador, el asegurado y el beneficiario en el Seguro de Pérdida de Licencia?',
                'text' : 'Lo normal es que sea el piloto el tomador, asegurado y beneficiario. No obstante, el tomador podría ser cualquiera que tenga interés manifiesto en cubrir el riesgo al que está expuesto un piloto en concreto al perder la licencia. El asegurado y el beneficiario necesariamente es el piloto.'
            },
            {
                'title' : 'Si cambio mi seguro de pérdida de licencia al nuevo asegurador perderé mis derechos en Sepla o Copac?',
                'text' : 'No. Sepla tiene un acuerdo de colaboración con una correduría para facilitarle un tipo de seguro de pérdida de licencia, pero en ningún caso su contratación es obligatoria, ni podría serlo en un futuro, ya que un planteamiento así es totalmente contrario a los principios generales de cualquier sindicato.'
            }
        ]
    },
    {
        'title' : 'Coberturas',
        'questions' : [
            {
                'title' : 'En caso de pérdida temporal de licencia ¿durante cuántos meses me pagarán? ¿me descontarán lo percibido si al final pierdo definitivamente la licencia?',
                'text' : 'El período máximo de pérdida temporal es de 18 meses. Si transcurrido ese tiempo perdiera la licencia definitivamente se le descontaría lo percibido anteriormente.<br><br>Cualquier seguro de pérdida de licencia le descontará lo percibido durante la baja temporal, ya que la indemnización máxima a percibir por un mismo siniestro se establece en la Suma Asegurada contratada.'
            },
            {
                'title' : '¿Puedo estar en dos compañías aseguradoras distintas?¿Cobraré más indemnización?',
                'text' : 'En el caso de contratar en dos compañías distintas – una póliza para la pérdida temporal y otra póliza para la pérdida definitiva – usted percibirá las dos indemnizaciones. Primero la temporal por los días que esté en esta contingencia hasta la declaración de la pérdida definitiva. En ese momento se cobraría la totalidad de la indemnización por el capital asegurado en la póliza de la pérdida definitiva.'
            },
            {
                'title' : 'No soy piloto de línea aérea, sino instructor en una escuela de vuelo ¿Puedo contratar el Seguro de Pérdida de Licencia?',
                'text' : 'Sí. El seguro de Pérdida de Licencia definitiva está disponible para todos los pilotos, incluidos los de trabajos aéreos, escuelas, pilotos de aviación corporativa, etc. El único requisito es disponer de un contrato indefinido para optar al Seguro de Pérdida de Licencia.'
            },
            {
                'title' : 'Quiero seguir con mi actual seguro, sólo me cubre 300.000€ ¿puedo contratar el complemento con el seguro que ofrece Extracrew?',
                'text' : 'Por supuesto, siempre y cuando la suma asegurada del conjunto de las dos pólizas no sea superior a 500.000€. Usted tendrá dos seguros de Pérdida de Licencia y cobrará dos indemnizaciones en caso de siniestro. Después de percibir la indemnización por su aseguradora actual, el asegurador aportará la indemnización complementaria hasta cubrir la suma asegurada contratada.<br><br>Solicite información Rellene el formulario y un técnico especializado le atenderá y le guiará en los pasos a seguir para formalizar el contrato del seguro.<br><br>También puede solicitar información escribiendo un e-mail a extracrew@santasusana.com o visitar las oficinas de la aseguradora en Barcelona (requiere cita previa).'
            }
        ]
    }
];