import { createApp } from 'vue';
import { IonicVue, createAnimation, IonContent, IonHeader } from '@ionic/vue';
import Loader from '../widgets/loader/loader.vue';
import SvgIcon from '../widgets/svg-icon/svg-icon.vue';
import FormInput from '../widgets/form-input/form-input.vue';
import createRouter from './router';
import AppButton from '../widgets/app-button/app-button.vue';
import { IonRippleEffect } from '@ionic/vue';
import SafeArea from '../widgets/safe-area/safe-area.vue';
import ClickEffect from '../widgets/click-effect/click-effect.vue';
import translate from './translator';
import System from './system';

export default class AppManager {

   private static _original : any = {}
   private static router: any;

   public static init(App: any, routes: any, config: any, locales: any) : Promise<any> {
       this._original = config;

       // Translations
      translate.initialize(locales, config.defaultLanguage);

       return new Promise((resolve, reject) => {

            const router = createRouter(routes);
            this.router = router;

            /*
            Custom animation between screens
            const myAnimation = (baseEl: any, opts: any) => {
                const { enteringEl, leavingEl } = opts;
              
                const enteringPage = createAnimation('entering-page-animation')
                  .addElement(enteringEl)
                  .fromTo('opacity', 1, 0);
              
                const leavingPage = createAnimation('leaving-page-animation')
                  .addElement(leavingEl)
                  .fromTo('opacity', 0, 1);
              
                return createAnimation('root-transition')
                  .duration(500)
                  .easing('ease-in-out')
                  .addAnimation([enteringPage, leavingPage]);
            }*/

            const app = createApp(App)
            .use(IonicVue, {
                animated: true,
                //navAnimation: myAnimation
              })
            .use(router);

            app.component('loader', Loader as any);
            app.component('svg-icon', SvgIcon as any);
            app.component('form-input', FormInput as any);
            app.component('app-button', AppButton as any);
            app.component('ion-ripple-effect', IonRippleEffect);
            app.component('click-effect', ClickEffect as any);
            app.component('safe-area', SafeArea as any);
            app.component('ion-content', IonContent);
            app.component('ion-header', IonHeader);

            router.isReady()
            .then(() => {
                app.mount('#app');

                System.init();
                resolve(app);
            });

       });
   }

   public static getRouter() : any {
       return this.router;
   }

   public static get() : any {
       return this._original;
   }

   public static defaultLanguage() : string {
       let l = this._original['defaultLanguage'];
       return l ? l : 'en';
   }

   public static defaultIconSource() : string {
       let s = this._original['defaultIconSource'];
       return s ? s : 'heroicons-solid';
   }

}