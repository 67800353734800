import { defineComponent } from 'vue';
import translate from './translator';
import Utils from './utils';
import Time from './time';
import Storage from './storage';
import AppManager from './manager';
import App from './app';
import Nav from './nav';

export default defineComponent({

    data() {
        return {
            lib: {
                utils: Utils,
                time: Time,
                storage: Storage,
                config: AppManager.get()
            }
        }
    },

    mounted() {
        Nav.clear();
    },

    methods: {
        $t(key: string, params?: any) {
            return translate.get(key, params);
        },

        hasEvent(name: string) {
            return this.$attrs[name];
        },

        to(page: string, data: any = null) {

            if (data) {
                Object.keys(data).forEach((key: string) => {
                    Nav.set(key, data[key]);
                });
            }

            AppManager.getRouter().push(page);
        },

        back() {
            AppManager.getRouter().back();
        },

        ref(name: string) : any {
            return this.$refs[name] as any;
        },

        closeModal() {
            return App.modal.close();
        }
    }

});