import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href", "xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    class: _normalizeClass(["icon sprite-icons", _ctx.classForIcon()]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickEvent($event)))
  }, [
    _createElementVNode("use", {
      href: '/svg-icons/' + _ctx.lib + '.svg#i-' + _ctx.iconName,
      "xlink:href": '/svg-icons/' + _ctx.lib + '.svg#i-' + _ctx.iconName
    }, null, 8, _hoisted_1)
  ], 2))
}