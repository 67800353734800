import Login from './pages/login/login.vue';
import Tabs from './pages/tabs/tabs.vue';
import Forum from './pages/forum/forum.vue';
import Topic from './pages/topic/topic.vue';
import Chat from './pages/chat/chat.vue';
import Exam from './pages/exam/exam.vue';
import Post from './pages/post/post.vue';
import Notifications from './pages/notifications/notifications.vue';
import School from './pages/school/school.vue';
import Profile from './pages/profile/profile.vue';
import Category from './pages/category/category.vue';
import EditProfile from './pages/edit-profile/edit-profile.vue';

export default [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/tabs',
    name: 'Tabs',
    component: Tabs
  },
  {
    path: '/forums/:id',
    name: 'Forum',
    component: Forum
  },
  {
    path: '/forums/:forum/topic/:id',
    name: 'Topic',
    component: Topic
  },
  {
    path: '/chat/:userId',
    name: 'Chat',
    component: Chat
  },
  {
    path: '/post/:id',
    name: 'Post',
    component: Post
  },
  {
    path: '/exam/:id',
    name: 'Exam',
    component: Exam
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications
  },
  {
    path: '/school/:id',
    name: 'School',
    component: School
  },
  {
    path: '/profile',
    name: 'SelfProfile',
    component: Profile
  },
  {
    path: '/profile/:id',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/profile/edit',
    name: 'EditProfile',
    component: EditProfile
  },
  {
    path: '/category/:id',
    name: 'Category',
    component: Category
  }
];
