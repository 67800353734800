
import {defineComponent} from 'vue';

export default defineComponent({

    props: [ 'iconleft', 'iconright', 'loading', 'disabled', 'content' ],

    data() { 

        let data : {
            lefticon: {name: string, source: string|null}|null,
            righticon: {name: string, source: string|null}|null
        } = {
            lefticon: null,
            righticon: null
        }

        return data
    },

    created() {
        if (this.iconleft) {
            if (typeof this.iconleft == 'string') {
                this.lefticon = {
                    name: this.iconleft,
                    source: null
                };
            } else {
                this.lefticon = this.iconleft;
            }
        }

        if (this.iconright) {
            if (typeof this.iconright == 'string') {
                this.righticon = {
                    name: this.iconright,
                    source: null
                };
            } else {
                this.righticon = this.iconright;
            }
        }
    },

    methods: {

        isDisabled() {
            if (!this.disabled) return false;
            if (typeof this.disabled == 'boolean') return this.disabled;
            return this.disabled();
        }

    }

})