import { defineComponent } from 'vue';;

export default defineComponent({

    components: { },

    props: ['url', 'ratio'],

    methods: {
    }

})