import httpClient from "@/modules/httpClient";
import Reply from "./Reply";
import Term from "./Term";

export default class Post {

    ID: number = 0;
    title: string = '';
    link: string = '';
    image: string = '';

    content: string = '';
    author: {
        name: string,
        description: string,
        signature: string
    }|null = null;
    date: string = '';
    location: string = '';
    source: string = '';

    categories: Term[] = [];
    tags: Term[] = [];
    related: Post[] = [];

    constructor(data? : any) {
        if (!data) return;

        this.ID = data.ID;
        this.title = data.title;
        this.link = data.link;
        this.image = data.image;
        this.date = data.date;

        if (data.content) this.content = data.content;
        if (data.author) this.author = data.author;
        if (data.location) this.location = data.location;
        if (data.source) this.source = data.source;
        if (data.categories) {
            this.categories = data.categories.map((item: any) => new Term(item, 'category'));
        }
        if (data.tags) {
            this.tags = data.tags.map((item: any) => new Term(item, 'tag'));
        }
        if (data.related) {
            this.related = data.related.map((item: any) => new Post(item));
        }
    }

    get categoriesHTML() : string {
        let str = '';

        for(let i = 0; i < this.categories.length; ++i) {
            const cat = this.categories[i];
            str += `<a href="/category/${cat.slug}">${cat.name}</a>`;

            if (i < this.categories.length - 1) {
                str += '<br>';
            }
        }

        return str;
    }

    static homeContent() {
        return new Promise((resolve, reject) => {

            httpClient.get('posts.home')
            .then((res: any) => {
                res.partners = res.partners.map((item: any) => new Post(item));
                res.featured = res.featured.map((item: any) => new Post(item));
                res.articles = res.articles.map((item: any) => new Post(item));
                res.formation = res.formation.map((item: any) => new Post(item));
                res.single = new Post(res.single);
            
                Object.keys(res.banners)
                .forEach((k: string) => {
                    res.banners[k] = new Post(res.banners[k]);
                });

                resolve(res);
            });

        });
    }

    static get(ID: number) : Promise<Post> {
        return new Promise((resolve, reject) => {
            httpClient.get('posts.get', {id: ID})
            .then(response => {
                resolve(new Post(response));
            })
        })
    }

    static byTerm(slug: string, type: 'category'|'tag', offset: number = 0) : Promise<Post[]> {
        return new Promise(resolve => {

            const params: any = {
                offset
            }
            params[type] = slug;

            httpClient.get('posts.get', params)
            .then(response => {
                resolve(response.map((item: any) => new Post(item)))
            })
        })
    }

    comments() : Promise<Reply[]> {
        return new Promise(resolve => {
            httpClient.get(['posts.comments', {id: this.ID}])
            .then(response => {
                resolve(response.map((item: any) => new Reply(item, 'DD/MM/YYYY HH:mm')))
            })
        })
    }

    publishComment(content: String) {
        return new Promise(resolve => {
            httpClient.post('posts.comments', {
                post: this.ID,
                content
            })
            .then(resolve)
            .catch(resolve);
        })
    }

}