import Utils from './utils';

export default class ApiMap {

    map: any = {}

    constructor(apiMap: any) {
        this.map = apiMap;
    }

    get(url: string|any[]) {

        let endpoint = Array.isArray(url) ? {name: url[0], parameters: url[1]} : {name: url, parameters: {}};

        let key = typeof endpoint == 'string' ? endpoint : endpoint.name;
        let parameters = typeof endpoint == 'string' ? {} : endpoint.parameters;

        let value = Utils.dotNotation(this.map, key);

        Object.keys(parameters).forEach( (k: string) => {
            value = value.replace(':' + k, parameters[k]);
        });
        
        return this.map._base + (value ? value : '');

    }

    hasFake(url: string|any[], method: 'GET'|'POST'|'PUT'|'DELETE'|'PATCH') {

        if (!this.map._factory) {
            return null;
        }

        let endpoint = Array.isArray(url) ? {name: url[0], parameters: url[1]} : {name: url, parameters: {}};

        let key = endpoint.name;
        let fake = Utils.dotNotation(this.map._factory.interceptor(), key, null);
        if (!fake) return null;
        if (!fake[method]) return null;

        return fake[method];
    }

}