<template>
    <div class="section-splitter flex-row">
        <div class="flex-grow"/>
        <div class="section-splitter-title">
            <slot />
        </div>
        <div class="flex-grow"/>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
})
</script>

<style lang="scss">
.section-splitter {
    margin: 20px 0;

    > .flex-grow {
        height: 1px;
        background-color: lightgray;
        opacity: .6;
    }

    &-title {
        margin: 0 10px;
        font-size: 12px;
        color: gray;
    }

}
</style>