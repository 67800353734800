import { defineComponent } from 'vue';
import { App, AppComponent, FormInput } from 'extracrew-ionic-lib';
import httpClient from '@/modules/httpClient';

export default defineComponent({

    extends: AppComponent,

    components: {
        FormInput
    },

    data() {
        const data : {
            data: {
                name: string,
                email: string,
                type: string
            }
        } = {
            data: {
                name: '',
                email: '',
                type: 'seguros'
            }
        }

        return data;
    },

    methods: {
        submit() {
            if (!this.data.name || !this.data.email) return;

            httpClient.post('auth.contact', this.data)
            .then(() => {
                App.displayAlert({
                    text: 'Contacto enviado, nos pondremos en contacto contigo en breves a través de la dirección de correo que has proporcionado.',
                    buttonLeft: {
                        text: 'De acuerdo, ¡gracias!',
                        onClick: () => {}
                    }
                })
            });
            this.data = {
                name: '',
                email: '',
                type: 'seguros'
            };
        }
    }

});