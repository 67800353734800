import { defineComponent } from 'vue';;
import App from '../../modules/app';

export default defineComponent({

  components: { },

  props: ['url', 'zoomUrl'],

  methods: {
      zoom() {
        App.zoomedImage.open(this.zoomUrl ? this.zoomUrl : this.url);
      }
  }

})