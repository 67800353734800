import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createBlock(_component_svg_icon, {
    name: "x",
    source: "heroicons-solid",
    style: _normalizeStyle(_ctx.getStyle()),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.doClick()))
  }, null, 8, ["style"]))
}