import { AppManager, http, HTTPError, Request } from 'extracrew-ionic-lib';
import { apiMap } from '../api-map'; 

let httpClient = new http({
    apiMap: apiMap,
    headers: (req: Request) => {

        /**
         * Aquí puedes devolver si hay que enviar
         * headers especiales para esta llamada.
         */
        return new Promise((resolve, reject) => {

            resolve({});

        });

    },
    checkTokenExpired: (error: HTTPError) => {
        return new Promise((resolve, reject) => {

            if (error.code == 401) {
                AppManager.getRouter().push('/login');
                resolve(false);
            }

            resolve(false);

        });
    },

    refreshToken: (refreshToken: string) => {

        return new Promise((resolve, reject) => {

            /**
             * This is called when checkTokenExpired resolved true
             * 
             * Here resolve false if the token could not be refreshed,
             * true if token was refreshed.
             */

            resolve(false);

        });

    },
    
    validateToken: (token: string, refreshToken?: string) => {

        /*
        This tells you if the token is valid or not.
        If not, you will be redirected to the login.
        */

        return new Promise((resolve, reject) => {
            resolve(true);
        });

    }
});

export default httpClient;