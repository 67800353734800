import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "user-snippet flex-row",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openProfile()))
  }, [
    (_ctx.user.image)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "bgimage",
          style: _normalizeStyle({"background-image": "url(\"" + _ctx.user.image + "\")"})
        }, null, 4))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, _toDisplayString(_ctx.user.username), 1)
  ]))
}