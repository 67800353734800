let config = {
    title: 'Ionic App',
    defaultLanguage: 'es',
    api: {
      url: 'https://dev.extracrew.com/api',
      mockUp: true,
    },
    defaultIconSource: 'heroicons-solid'
  }//end [No elimines esto]
  
  export default config