/**
 * Customized translations.
 * 
 * Default translations in /lib/src/modules/locales
*/

export const locale_es = {
    profile: {
        props: {
            gender: 'Sexo',
            city: 'Ciudad',
            company: 'Aerolínea',
            profession: 'Profesión',
            country: 'Country',
            phone: 'Teléfono',
            postalcode: 'Código postal',
            province: 'Provincia',
            website: 'Sitio web'
        }
    }
}