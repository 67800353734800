import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "page-title" }
const _hoisted_2 = { class: "category-page" }
const _hoisted_3 = {
  key: 1,
  class: "posts"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_loader = _resolveComponent("loader")!
  const _component_post_card = _resolveComponent("post-card")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_safe_area = _resolveComponent("safe-area")!

  return (_openBlock(), _createBlock(_component_safe_area, { id: "category-page" }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_svg_icon, {
          name: "heroicons-solid/chevron-left",
          class: "back",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.back()))
        }),
        (_ctx.category)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "title",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.back()))
            }, _toDisplayString(_ctx.category.name), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.posts)
          ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.posts)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (post) => {
                return (_openBlock(), _createBlock(_component_post_card, {
                  post: post,
                  key: post.ID
                }, null, 8, ["post"]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_ion_infinite_scroll, { onIonInfinite: _ctx.loadMore }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_infinite_scroll_content)
          ]),
          _: 1
        }, 8, ["onIonInfinite"])
      ])
    ]),
    _: 1
  }))
}