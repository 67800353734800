import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/vue';
import { defineComponent } from 'vue';
import { AppComponent, Utils, SafeArea, FormInput, CloseX } from 'extracrew-ionic-lib';
import Topic from '@/models/Topic';
import Reply from '@/models/Reply';
import UserSnippet from '@/components/user-snippet/user-snippet.vue';

export default defineComponent({

    name: 'topic',
    extends: AppComponent,

    components: {
        SafeArea,
        IonInfiniteScroll,
        IonInfiniteScrollContent,
        UserSnippet,
        FormInput,
        CloseX
    },

    data() {
        const data : {
            topic: Topic|null,
            replies: Reply[]|null,
            finished: boolean,
            loading: boolean,
            replying: boolean,
            reply: string,
            sending: boolean
        } = {
            topic: null,
            replies: null,
            finished: false,
            loading: false,
            replying: false,
            reply: '',
            sending: false
        }

        return data;
    },

    created() {
        Topic.get(this.$route.params.forum, this.$route.params.id)
        .then(topic => {
            this.topic = topic;

            this.loadMoreReplies();
        })
    },

    methods: {

        sendReply() {
            if (this.reply == '') return;

            this.sending = true;
            this.topic!.reply(this.reply)
            .then(() => {
                this.loadMoreReplies(null, true);
                this.sending = false;
            })

            this.reply = '';
            this.replying = false;
        },

        loadMoreReplies($e?: any, reset?: boolean) {

            if (this.finished || this.loading) {
                if ($e) {
                    $e.target.complete();
                }

                return;
            }

            this.loading = true;

            this.topic!.replies(this.replies && !reset ? this.replies.length : 0)
            .then(res => {

                this.loading = false;
                this.sending = false;

                if (reset) {
                    this.replies = null;
                }

                if (res.items.length == 0) {
                    this.finished = true;
                    if (!this.replies) this.replies = [];
                } else {
                    this.replies = Utils.addToList(this.replies, res.items);
                }

                if ($e) {
                    $e.target.complete();
                }
            })
            .catch(() => {
                this.sending = false;
                this.loading = false;
            })
        }
    }

});