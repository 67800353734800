import { defineComponent } from 'vue';
import { AppComponent, AppForm } from 'extracrew-ionic-lib';
import faqs from './faqs';
import SegurosForm from './seguros-form/seguros-form.vue';

export default defineComponent({

    extends: AppComponent,

    components: { AppForm, SegurosForm },

    data() {
        const data : {
            form: (any[])|null,
            formData: {
                name: string,
                lastname: string,
                age?: number,
                type: 'piloto'|'tcp',
                company: string,
                email: string,
                phone: string
            },
            features: any[],
            faqs: any[]
        } = {
            form: null,
            formData: {
                name: '',
                lastname: '',
                age: undefined,
                type: 'piloto',
                company: '',
                email: '',
                phone: ''
            },
            features: [
                {
                    icon: 'extracrew/star',
                    text: 'Límites de indemnización más altos del mercado'
                },
                {
                    icon: 'extracrew/loan',
                    text: 'Un precio de producto muy competitivo'
                },
                {
                    icon: 'extracrew/officer',
                    text: 'Seguro personalizado con las menores exclusiones'
                }
            ],
            faqs: faqs
        }

        return data;
    },

    created() {

        this.form = [
            {
                width: '100%',
                fields: [
                    {
                        name: 'name',
                        model: this.formData,
                        required: true,
                        label: 'Nombre'
                    },
                    {
                        name: 'lastname',
                        model: this.formData,
                        required: true,
                        label: 'Apellidos'
                    }
                ]
            },
            {
                width: '50%',
                fields: [
                    {
                        name: 'age',
                        model: this.formData,
                        type: 'number',
                        required: true,
                        label: 'Edad'
                    }
                ]
            },
            {
                width: '50%',
                fields: [
                    {
                        name: 'type',
                        model: this.formData,
                        type: 'select',
                        label: 'Piloto / TCP',
                        required: true,
                        options: () => [
                            { value: 'piloto', content: 'Piloto' },
                            { value: 'tcp', content: 'TCP' }
                        ]
                    }
                ]
            },
            {
                width: '100%',
                fields: [
                    {
                        name: 'company',
                        model: this.formData,
                        required: true,
                        label: 'Compañía aérea'
                    },
                    {
                        name: 'email',
                        model: this.formData,
                        type: 'email',
                        required: true,
                        label: 'Correo electrónico'
                    },
                    {
                        name: 'phone',
                        model: this.formData,
                        required: true,
                        label: 'Teléfono'
                    }
                ]
            }
        ]

    },

    methods: { }

});