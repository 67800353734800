import { defineComponent } from 'vue';
import { AppComponent, Tabbar, SafeArea } from 'extracrew-ionic-lib';
import home from './tabs/home/home.vue';
import education from './tabs/education/education.vue';
import forum from './tabs/forum/forum.vue';
import seguros from './tabs/seguros/seguros.vue';
import conversations from './tabs/conversations/conversations.vue';
import { Capacitor } from '@capacitor/core';

export default defineComponent({

    name: 'tabs',
    extends: AppComponent,

    components: {
        SafeArea,
        Tabbar,
        home,
        education,
        forum,
        conversations,
        seguros
    },

    data() {
        const data : {
            isWeb: boolean,
            tab: string
        } = {
            isWeb: false,
            tab: 'forum'
        }

        return data;
    },

    created() {
        this.isWeb = Capacitor.getPlatform() == 'web';
    },

    methods: { }

});