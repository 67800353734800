import httpClient from "@/modules/httpClient";

export default class School {

    ID: number = 0;
    name: string = '';
    zone: string = '';
    target: string = '';
    licenses: string = '';
    featured: boolean = false;

    image: string = '';
    web: string = '';
    mail: string = '';
    phone: string = '';
    address: string = '';
    courses: string = '';
    fleet: string = '';
    airport: string = '';

    constructor(data: any = null) {
        if (!data) return;

        this.ID = data.ID;
        this.name = data.name;
        this.zone = data.zone;
        this.target = data.target;
        this.licenses = data.licenses;
        this.featured = data.featured;

        this.image = data.image;
        this.web = data.web;
        this.mail = data.mail;
        this.phone = data.phone;
        this.address = data.address;
        this.courses = data.courses;
        this.fleet = data.fleet;
        this.airport = data.airport;
    }

    static list() : Promise<{schools: School[]}> {
        return new Promise((resolve, reject) => {
            httpClient.get('schools.list')
            .then(res => {

                resolve({
                    schools: res.schools.map((item: any) => new School(item))
                })

            })
        })
    }

    static get(id: number) : Promise<School> {
        return new Promise(resolve => {

            httpClient.get('schools.list', {id})
            .then(response => {
                resolve(new School(response));
            })

        });
    }

}