import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { ref: "mark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock("div", {
    class: "infinite-scroll custom-scrollbar",
    onScroll: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleScroll($event))),
    ref: "container",
    style: _normalizeStyle({
        'display': _ctx.reverse ? 'flex' : 'block',
        'flex-direction': _ctx.reverse ? 'column-reverse' : ''
    })
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.emptyMessage && _ctx.list && _ctx.list.length == 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "empty-message",
          innerHTML: _ctx.emptyMessage
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (!_ctx.list || _ctx.loading)
      ? (_openBlock(), _createBlock(_component_loader, { key: 1 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, null, 512)
  ], 36))
}