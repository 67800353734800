import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "app-form"
}
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_input = _resolveComponent("form-input")!

  return (_ctx.cols)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cols, (col, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(["col-1", _ctx.classForColumn(col)])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(col.fields, (field, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "app-form-input",
                key: index
              }, [
                (['text', 'password', 'email', 'number', 'textarea', 'checkbox', 'select', 'date', 'color', 'phone', 'country'].includes(field.type))
                  ? (_openBlock(), _createBlock(_component_form_input, _mergeProps({
                      key: 0,
                      modelValue: field.model[field.name],
                      "onUpdate:modelValue": ($event: any) => ((field.model[field.name]) = $event)
                    }, field.props, {
                      type: field.type
                    }), null, 16, ["modelValue", "onUpdate:modelValue", "type"]))
                  : _createCommentVNode("", true),
                (field.type == 'custom')
                  ? (_openBlock(), _createElementBlock("div", _mergeProps({
                      key: 1,
                      innerHTML: field.props.content
                    }, _ctx.props), null, 16, _hoisted_2))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ], 2))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}