import { defineComponent } from 'vue';

export default defineComponent({

    props: [ 'categories', 'onBottomReached' ],

    data() {
        let data : {
            refs: any,
            activeCategory: any,
            bottomCheck: boolean
        } = {
            refs: {},
            activeCategory: null,
            bottomCheck: true
        }

        return data;
    },

    methods: {
        setItemRef(index: number, el: any) {
            this.refs[index] = el;
        },

        handleScroll(e:any) {
            let container = (this.$refs.container as any);

            let ac = 0;
            let cat = null;
            
            for(let i = 0; i < this.categories.length; ++i) {
                if (!this.refs[i]) continue;
                let el = this.refs[i];

                let height = el.offsetHeight;

                if (ac + height < container.scrollTop) {
                    cat = this.categories[i];
                    ac += height;
                }
                else {
                    cat = this.categories[i];
                    break;
                }

            }

            this.activeCategory = cat;

            if (this.bottomCheck && this.onBottomReached) {
                if (container.scrollTop + container.offsetHeight >= container.scrollHeight - 100) {
                    this.bottomCheck = false;
                    this.onBottomReached()
                    .then(() => {
                        setTimeout(() => {
                            this.bottomCheck = true;
                        }, 100);
                    });
                }
            }

        }
    }

});