import { defineComponent } from 'vue';

export default defineComponent({

    props: ['label'],

    data() {
        let data : {
            status: 'closed'|'expanding'|'expanded'
        } = {
            status: 'closed'
        };
        return data;
    },

    methods: {
        toggle() {

            /**
             * El tema de la animación de momento se ha dejado
             * porque no quedaba muy bien.
             */

            if (this.status == 'closed') {
                this.status = 'expanded';
            } else {
                this.status = 'closed';
            }

        },

        close() {
            this.status = 'closed';
        }
    }

});