import { defineComponent } from 'vue';

export default defineComponent({

    props: ['src', 'height', 'width'],

    data() {
        let data : {
            loaded: boolean
        } = {
            loaded: false
        }

        return data;
    }

});