import { defineComponent } from 'vue';
import { AppComponent, SearchBar, Store } from 'extracrew-ionic-lib';
import Forum from '@/models/Forum';
import Topic from '@/models/Topic';
import TopicCard from '@/components/topic-card/topic-card.vue';

export default defineComponent({

    extends: AppComponent,

    components: { SearchBar, TopicCard },

    data() {
        const data : {
            forums: Forum[]|null,
            search: string,
            searchResult: Topic[]|null
        } = {
            forums: null,
            search: '',
            searchResult: null
        }

        return data;
    },

    created() {

        this.forums = Store.getSync('forums');

        Forum.list()
        .then(forums => {
            this.forums = forums;
            Store.set('forums', forums);
        })
    },

    methods: {
        doSearch() {
            if (!this.search) {
                this.searchResult = null;
            } else {
                Topic.search(this.search)
                .then(res => {
                    this.searchResult = res;
                })
            }
        }
    }

});