
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import { AppComponent } from 'extracrew-ionic-lib';
import auth from '@/modules/auth';

export default defineComponent({

  name: 'Home',
  extends: AppComponent,

  components: {
    IonContent,
    IonPage,
  },

  data() {
    let data : {
      loading: boolean,
      credentials: {
        username: string,
        password: string
      },
      btnLoading: boolean,
      error: boolean
    } = {
      loading: true,
      credentials: {
        username: '',
        password: ''
      },
      btnLoading: false,
      error: false
    }

    return data;
  },

  created() {
    
    auth.validate()
    .then(() => {
      this.loading = false;
      this.to('/tabs');
    })
    .catch(() => {
      this.loading = false;
    });
  },

  methods: {
    doLogin() {
      if (!this.credentials.username || !this.credentials.password) return;

      this.error = false;
      this.btnLoading = true;

      auth.login(this.credentials)
      .then(() => {
        this.to('/tabs');
        setTimeout(() => {
          this.btnLoading = true;
        }, 200);
      })
      .catch(err => {
        this.error = true;
        this.btnLoading = false;
      })
    }
  }

});