
export default class ForumSection {

    ID: number = 0;
    description: string = '';
    icon: string = '';
    name: string = '';
    topic_count: number = 0;
    last: {ID: number, name: string}|null = null;

    constructor(item?: any) {
        if (!item) return;

        this.ID = item.ID;
        this.description = item.description;
        this.icon = item.icon;
        this.name = item.name;
        this.topic_count = item.topic_count;
        this.last = item.last;
    }

}