import { defineComponent } from 'vue';
import { AppComponent, SafeArea, Utils, Nav } from 'extracrew-ionic-lib';
import Post from '@/models/Post';
import PostCard from '@/components/post-card/post-card.vue';
import Term from '@/models/Term';

export default defineComponent({

    name: 'category',
    extends: AppComponent,

    components: {
        SafeArea,
        PostCard
    },

    data() {
        const data : {
            category: Term|null,
            posts: Post[]|null,
            finished: boolean
        } = {
            category: null,
            posts: null,
            finished: false
        }

        return data;
    },

    created() {
        this.category = Nav.get('category');
        this.loadMore();
    },

    methods: {
        loadMore($e: any = null) {
            if (this.finished) {
                if ($e) $e.target.complete();
                return;
            }

            const term : string = this.$route.params.id as string;
            Post.byTerm(term, 'category', this.posts ? this.posts.length : 0)
            .then(posts => {

                if (posts.length > 0)
                    this.posts = Utils.addToList(this.posts, posts);
                else
                    this.finished = true;
                
                if ($e) {
                    $e.target.complete();
                }
            });
        }
    }

});