import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, renderSlot as _renderSlot, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tabbar"
}
const _hoisted_2 = {
  key: 0,
  class: "tabbar-buttons top"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 1,
  class: "tabbar-buttons bottom"
}
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_click_effect = _resolveComponent("click-effect")!

  return (_ctx.tabs)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.position != 'bottom')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
                return (_openBlock(), _createBlock(_component_click_effect, {
                  class: _normalizeClass(["tabbar-button flex-grow flex-center", {active: tab.name == _ctx.selectedTab.name}]),
                  key: index,
                  onClick: ($event: any) => (_ctx.setSelected(tab))
                }, {
                  default: _withCtx(() => [
                    (tab.icon)
                      ? (_openBlock(), _createBlock(_component_svg_icon, {
                          key: 0,
                          name: tab.icon
                        }, null, 8, ["name"]))
                      : _createCommentVNode("", true),
                    (tab.label)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(tab.label), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["class", "onClick"]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.loadedTabs, (tab) => {
          return _withDirectives((_openBlock(), _createElementBlock("div", {
            class: "tabbar-content",
            key: tab.name
          }, [
            _createElementVNode("div", null, [
              _renderSlot(_ctx.$slots, tab.name)
            ])
          ])), [
            [_vShow, _ctx.selectedTab.name == tab.name]
          ])
        }), 128)),
        (_ctx.position == 'bottom')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
                return (_openBlock(), _createBlock(_component_click_effect, {
                  class: _normalizeClass(["tabbar-button flex-grow flex-center", {active: tab.name == _ctx.selectedTab.name}]),
                  key: index,
                  onClick: ($event: any) => (_ctx.setSelected(tab))
                }, {
                  default: _withCtx(() => [
                    (tab.icon)
                      ? (_openBlock(), _createBlock(_component_svg_icon, {
                          key: 0,
                          name: tab.icon
                        }, null, 8, ["name"]))
                      : _createCommentVNode("", true),
                    (tab.label)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(tab.label), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["class", "onClick"]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}