import { defineComponent } from 'vue';
import translate from '../../modules/translator';

export default defineComponent({
  components: { },

  props: ['placeholder', 'stopTime', 'onWrite', 'onStopWriting', 'onSearch'],

  data() {

    let data : {
      value: string, 
      suggestions: string[]|null,
      lastWrite: number
    } = {
      value: '',
      suggestions: null,
      lastWrite: -1
    }

    return data;
  },

  methods: {
      $t(key: string, parameters: any = {}) {
        return translate.get(key, parameters);
      },
      onInput(val: string) {

        this.value = val;
        this.$emit('update:modelValue', val);

        this.$emit('write', this);

        if (this.onWrite) {
          this.onWrite(this);
        }

        this.lastWrite = Date.now();

        let millis = (this.stopTime ? this.stopTime : 0.5) * 1000;

        setTimeout(() => {

          if (Date.now() - this.lastWrite >= millis) {
            this.$emit('stop', this);

            if (this.onStopWriting) {
              this.onStopWriting();
            }
          }

        }, millis);

      },
      setSuggestions(suggestions: string[]) {
        this.suggestions = suggestions;
      },
      selectSuggestion(suggestion: string) {
        this.value = suggestion;
        this.suggestions = null;
      },
      search() {
        this.$emit('search', this);

        if (this.onSearch) {
          this.onSearch(this);
        }
      },
      clear() {
        this.value = '';
        this.suggestions = null;
        this.$emit('update:modelValue', '');
      }
  }
})