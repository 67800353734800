export default {
    generic: {
        ok: 'OK',
        email: "Correu electrònic",
        username: "Nom d'usuari",
        email_username: "Correu electrònic o nom d'usuari",
        password: "Contrassenya",
        repassword: "Repetir contrassenya",
        phone: "Telèfon",
        delete: "Eliminar",
        edit: "Editar",
        logout: "Sortir",
        search: "Cercar",
        yes: "Si",
        no: "No",
        name: "Nom",
        surname: "Cognoms",
        fullname: "Nom complert",
        description: "Descripció",
        save: "Guardar",
        submit: "Enviar",
        add: "Agregar",
        cancel: "Cancel·lar",
        accept: "Acceptar",
        information: "Informació",
        no_results: "No hi ha resultats",
        continue: "Continuar",
        back: "Tornar",
        undefined: "No especificat",
        select: "Sel·leccionar",
        contact: "Contacte",
        create: "Crear",
        title: "Títol"
    },
    time: {
        date: "Data",
        hour: "Hora",
        day: "Dia"
    },
    address: {
        address: "Direcció",
        city: "Ciutat",
        street: "Carrer",
        zip: "Codi postal"
    },
    media: {
        upload: "Pujar",
        download: "Descarregar",
        image: "Imatge",
        photo: "Foto",
        video: "Video",
        format: "Format",
        size: "Tamany",
        select: "Sel·leccionar fitxer",
        sizeExceeded: "El fitxer és massa gran i excedeix el tamany màxim permés",
        select_image: "Sel·leccionar imatge",
        select_video: "Sel·leccionar video",
        crop: "Retallar"
    },
    login: {
        title: "Entrar",
        subtitle: "Bienvingut/da al panel de gestió",
        submit: "Entrar",
        no_have_account: "Encara no ets membre?",
        signup: "Registra't",
        forgot_password: "Has oblidat la teva contrassenya?",
        error: "Credencials incorrectes",
        empty_email: "Introdueix un correu electrònic vàlid",
        empty_username: "Introdueix un usuari vàlid",
        empty_password: "Introdueix una contrassenya"
    },
    reset_password: {
        title: "Actualitzar contrassenya",
        back: "Tornar al login",
        email: {
            title: "Introdueix el correu electrònic de la teva conta.",
            submit: "Rebre correu electrònic",
            success: "Has rebut un correu electrònic. Si us plau, prem l'enllaç per a seguir amb el procés.",
            error: "Hi ha hagut un error a l'enviament del correu electrònic."
        },
        password: {
            title: "Introdueix la nova contrassenya.",
            submit: "Canviar contrassenya",
            success: "La teva contrassenya ja ha sigut canviada, pots entrar.",
            button: "Iniciar sessió",
            empty: "La contrassenya no pot estar buida.",
            mismatch: "Les contrassenyes no coincideixen.",
            error: "L'enllaç que has utilitzat no és vàlid.",
            again: "Tornar a enviar correu electrònic"
        }
    },
    breadcrumbs: {
        home: "Inici"
    },
    errors: {
        not_found: "Vaja, no podem trobar el que busques.",
        unknown: "Vaja, hi ha hagut un error desconegut.",
        go_home: "Anar a inici"
    },
    confirm: {
        delete: "Estàs segur de que vols eliminar{{detail}}?",
        nosave: "Vols sortir sense guardar?"
    },
    table: {
        empty: "No hi ha elements",
        filters: "Filtres",
        loading: "Carregant continguts...",
        count: "{{count}} elements",
        info: "Informació bàsica",
        refresh: "Recarregar",
        per_page: "Elements per pàgina",
        page: "de {{total}}"
    },
    dashboard: {
        title: "Bienvingut al teu nou projecte"
    },
    users: {
        plural: "Usuaris",
        singular: "Usuari",
        add: "Agregar usuari",
        delete: "Eliminar usuari",
        edit: "Editar usuari"
    }
}