import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

export default function(pages: {path: string, name?: string, redirect?: string, component?: any}[]) {

    let routes: Array<RouteRecordRaw> = (pages as any);
      
    return createRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes
    });
    
}