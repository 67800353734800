import { defineComponent } from 'vue';

export default defineComponent({
    props: ['name', 'source', 'onClick', 'iconClass'],

    data() {
        let data : {
            iconName: '',
            lib: string
        } = {
            iconName: '',
            lib: ''
        }
        return data;
    },

    created() {
        this.parseName();
    },

    methods: {
        clickEvent($e: any) {
            if (this.onClick) {
                this.onClick($e);
            }
        },

        classForIcon() {
            let cl : any = {}

            if (this.iconClass) {
                cl[this.iconClass] = true;
            }

            return cl;
        },

        parseName() {
            this.iconName = this.name;
            if (this.name.indexOf('/') >= 0) {
                let parts = this.name.split('/');
                this.iconName = parts[1];
                this.lib = parts[0];
            } else {
                this.lib = this.source ? this.source : 'heroicons-solid';
            }
        }
    },

    watch: {
        $props: {
        handler() {
            this.parseName();
        },
        deep: true,
        immediate: true,
        },
    },
});