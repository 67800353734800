
export default class User {

    ID: number = 0;
    username: string = '';
    image: string = '';

    constructor(item?: any) {

        this.ID = item.ID;
        this.username = item.username;
        this.image = item.image;

    }

}