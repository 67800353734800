import { AppManager } from "extracrew-ionic-lib";

export default {

    fixHtml(content: string) {
        if (!content) return '';

        let str = content;

        str = content.replaceAll('src=\"/wp-content', 'src="' +
            AppManager.get().api.url.replace('/api', '')
            + '/wp-content');

        return str;
    }

}