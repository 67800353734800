import { defineComponent } from 'vue';

export default defineComponent({

    props: [ 'tabs', 'position', 'initial' ],

    data() {
        let data : {
            selectedTab: any,
            loadedTabs: any[]
        } = {
            selectedTab: null,
            loadedTabs: []
        }

        return data;
    },

    created() {
        
        if (this.tabs && this.tabs.length > 0) {
            if (this.initial) {
                for(let t of this.tabs) {
                    if (t.name == this.initial) {
                        this.selectedTab = t;
                        break;
                    }
                }
            }

            if (!this.selectedTab) {
                this.selectedTab = this.tabs[0];
            }
        }

        if (this.selectedTab) {
            this.loadedTabs.push(this.selectedTab);
        }
    },

    methods: {

        select(name: string) {
            let t = null;
            for(let tab of this.tabs) {
                if (tab.name == name) {
                    t = tab;
                    break;
                }
            }

            if (t)
            this.selectedTab = t;

            //this.$emit('change', name);
        },

        setSelected(tab: any) {
            this.selectedTab = tab;

            if (!this.loadedTabs.map((item: any) => item.name)
                .includes(tab.name)) {
                this.loadedTabs.push(tab);
            }
            this.$emit('change', tab.name);
        }

    },

    watch: {
        $props: {
        handler() {
            if (!this.selectedTab && this.tabs.length > 0) {
                if (!this.initial) {
                    this.setSelected(this.tabs[0]);
                } else {
                    this.select(this.initial);
                }

            }
        },
        deep: true,
        immediate: true,
        },
    },

})