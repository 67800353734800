import httpClient from "@/modules/httpClient"
import ForumSection from "./ForumSection";

export default class Forum {

    ID: number = 0;
    name: string = '';
    sections: ForumSection[] = [];
    icon: string = '';
    description: string = '';

    constructor(item?: any) {
        this.ID = item.ID;
        this.name = item.name;
        this.sections = item.sections.map((item: any) => new ForumSection(item));
        this.icon = item.icon;
        this.description = item.description;
    }

    static list() : Promise<Forum[]> {
        return new Promise(resolve => {
            httpClient.get('forums.list')
            .then(res => {
                resolve(
                    res
                    .filter((item: any) => item.sections && item.sections.length > 0)
                    .map((item: any) => new Forum(item))
                );
            })
            .catch(err => {
                resolve([]);
            })
        })
    }

    static get(ID: any) : Promise<Forum> {
        return new Promise(resolve => {
            httpClient.get(['forums.get', {id: ID}])
            .then(res => {
                resolve(new Forum(res));
            });
        })
    }

}