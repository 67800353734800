import { defineComponent } from 'vue';

export default defineComponent({

    name: 'dropdown-menu',
   
    props: [ 'item', 'depth', 'onItemSelected', 'parent'],

    data() {

        let data : {
            expanded: boolean
        } = {
            expanded: false
        }

        return data;

    },

    methods: {
        select(item: any) {

            this.close();

            if (this.onItemSelected) {
                this.onItemSelected(item);
            }
        },

        close() {
            this.expanded = false;

            if (this.parent) {
                this.parent.close();
            }
        },

        asParent() { return this; }
    }

});