import httpClient from "@/modules/httpClient";
import { AppManager } from "extracrew-ionic-lib";

export default class Exam {

    ID: number = 0;
    title: string = '';

    isQuiz: boolean = false;
    score: number = 0;
    attempts: number = 0;
    categories: {ID: number, slug: string, name: string}[] = [];
    status: 'none'|'failed'|'passed'|'good'|'perfect' = 'none';
    numQuestions: number = 0;
    timelimit: number = 0;
    isExam: boolean = true;

    // Detail
    questions: any[] = [];
    message_before: string = '';
    message_after: string = '';

    constructor(item: any, isQuiz: boolean = false) {
        this.ID = item.ID;
        this.title = item.title;
        this.isQuiz = isQuiz;
        this.score = item.score;
        this.attempts = item.attempts;
        this.categories = item.categories ? item.categories : [];
        this.status = item.status;
        this.timelimit = item.timelimit;

        if (typeof item.questions == 'number') {
            this.numQuestions = item.questions;
        } else {
            // Detail
            this.isExam = item.isExam;
            this.questions = item.questions.map((item: any) => {
                return {
                    ...item,
                    description: item.description ?
                        item.description.replaceAll('/wp-content', AppManager.get().api.url.replace('/api', '') + '/wp-content')
                        : '',
                    values: []
                }
            });
            this.numQuestions = item.questions.length;
            this.message_after = item.message_after;
            this.message_before = item.message_before;
        }
    }

    get statusLabel() : string {
        if (this.status == 'failed') return 'No superado';
        if (this.status == 'passed') return 'Superado con fallos';
        if (this.status == 'good') return 'Notable';
        if (this.status == 'perfect') return 'Puntuación perfecta';

        return 'No intentado';
    }

    collectAnswers() {
        const answers : any = {}
        for(let q of this.questions) {
            if (q.multiple) {
                const a = [];
                for(let ans of q.answers) {
                    if (ans.checked) {
                        a.push(ans.index);
                    }
                }
                answers[q.index] = a;
            } else {
                answers[q.index] = [q.value];
            }
        }
        return answers;
    }

    /// API

    static list() : Promise<{
        exams: Exam[],
        categories: any[]
    }> {
        return new Promise((resolve, reject) => {
            httpClient.get('exams.list')
            .then((response) => {

                const exams : Exam[] = [];

                for(let e of response.exams) {
                    exams.push(new Exam(e));
                }
                for(let e of response.quiz) {
                    exams.push(new Exam(e, true));
                }

                resolve({
                    exams,
                    categories: response.categories
                })
            })
        });
    }

    static get(id: number|string) : Promise<Exam> {

        return new Promise(resolve => {

            httpClient.get(['exams.get', {id}])
            .then(response => {

                resolve(new Exam(response));

            })

        });

    }

    validate() : Promise<any> {
        return  httpClient.post(['exams.get', {id: this.ID}], {
            answers: this.collectAnswers()
        })
    }

}