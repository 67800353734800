import { defineComponent } from 'vue';
import { AppComponent, SafeArea } from 'extracrew-ionic-lib';
import Notification from '@/models/Notification';

export default defineComponent({

    name: 'notifications',
    extends: AppComponent,

    components: {
        SafeArea
    },

    data() {
        const data : {
            notifications: Notification[]|null
        } = {
            notifications: null
        }

        return data;
    },

    created() {
        Notification.list()
        .then(nots => {
            this.notifications = nots;
        });
    },

    methods: {
        notificationButtonClass(not: Notification) {
            const obj : any = {}
            obj[not.type] = true;
            return obj;
        },

        notificationButtonText(not: Notification) {
            return not.type == 'chat' ?
            'Ver conversación'
            : 'Ir al foro'
        },
        notificationButtonIcon(not: Notification) {
            return not.type == 'chat' ? 
            'geom/chat'
            : 'entypo/chat'
        }
    }

});