import { StatusBar, Style } from '@capacitor/status-bar';
import { ScreenOrientation, OrientationType } from '@capawesome/capacitor-screen-orientation';

export default {

    init() {
        this.statusBar.overlay(true);
        this.statusBar.setIconsBlack();
        this.orientation.lock();
    },

    get statusBar() : { setIconsBlack: () => void, setIconsWhite: () => void, overlay: (overlays:boolean) => void } {
        return {
            setIconsBlack() {
                StatusBar.setStyle({ style: Style.Light });
            },
            setIconsWhite() {
                StatusBar.setStyle({ style: Style.Dark });
            },
            overlay(overlays: boolean) {
                StatusBar.setOverlaysWebView({overlay: overlays});
            }
        }
    },

    get orientation() : {
        forceLandscape: () => Promise<any>,
        lock: () => Promise<any>, 
        unlock: () => Promise<any>,
        get: () => Promise<string>
    } {

        return {
            lock() {
                return ScreenOrientation.lock({ type: OrientationType.PORTRAIT_PRIMARY });
            },
            forceLandscape() {
                return ScreenOrientation.lock({ type: OrientationType.LANDSCAPE });
            },
            unlock() {
                return ScreenOrientation.unlock();
            },
            get() {
                return new Promise((resolve, reject) => {
                    ScreenOrientation.getCurrentOrientation()
                    .then((orientation: any) => {
                        resolve(orientation.type);
                    })
                });
            }
        }

    }

}