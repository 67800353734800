import helper from "@/modules/helper";
import httpClient from "@/modules/httpClient"
import { Time } from "extracrew-ionic-lib";
import Reply from "./Reply";
import User from "./User";

export default class Topic {

    ID: number = 0;
    date: Time = new Time();
    title: string = '';
    author: User|null = null;
    replyCount: number = 0;
    lastAnswer: {
        ID: number,
        date: Time,
        author: User
    }|null = null;
    content: string = '';

    constructor(item?: any) {
        this.ID = item.ID;
        this.date = new Time(item.date);
        this.title = item.title;
        this.author = new User(item.author);
        this.replyCount = item.replyCount ? item.replyCount : 0;
        this.content = helper.fixHtml(item.content);

        if (item.lastAnswer) {
            this.lastAnswer = {
                ID: item.lastAnswer.ID,
                date: new Time(item.lastAnswer.date),
                author: new User(item.lastAnswer.author)
            }
        }

    }

    static list(forumId: any, offset: number = 0) : Promise<Topic[]> {
        return new Promise(resolve => {
            httpClient.get(['forums.topics', {forumId,offset}])
            .then(res => {
                resolve(
                    res.items
                    .map((item: any) => new Topic(item))
                );
            })
            .catch(err => {
                resolve([]);
            })
        })
    }

    static search(text: string) : Promise<Topic[]> {
        return new Promise(resolve => {

            httpClient.get(['forums.search', {search: text}])
            .then(res => {
                resolve(
                    res.items
                    .map((item: any) => new Topic(item))
                );
            })
            .catch(err => {
                resolve([]);
            })

        });
    }

    static get(forum: any, id: any) : Promise<Topic> {
        return new Promise(resolve => {
            httpClient.get(['forums.topic', {forum, id}])
            .then(res => {
                resolve(new Topic(res));
            })
        })
    }

    replies(offset: number = 0) : Promise<{
        items: Reply[],
        offset: number,
        total: number
    }> {
        return new Promise(resolve => {

            httpClient.get(['forums.replies', {id: this.ID}], {
                offset
            })
            .then(response => {
                resolve({
                    ...response,
                    items: response.items.map((item: any) => new Reply(item))
                })
            });

        })
    }

    reply(text: string) : Promise<any> {
        return new Promise(resolve => {

            httpClient.post(['forums.replies', {id: this.ID}], {
                text
            })
            .then(response => resolve(true));

        });
    }

}