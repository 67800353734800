import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "forum-box topic-card" }
const _hoisted_2 = { class: "forum-title" }
const _hoisted_3 = { class: "author" }
const _hoisted_4 = {
  key: 0,
  class: "replyCount"
}
const _hoisted_5 = {
  key: 1,
  class: "replyCount"
}
const _hoisted_6 = {
  key: 2,
  class: "replyCount"
}
const _hoisted_7 = {
  key: 3,
  class: "lastAnswer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.topic.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _createTextVNode(" Creado por "),
      _createElementVNode("b", null, _toDisplayString(_ctx.topic.author.username), 1),
      _createTextVNode(" el " + _toDisplayString(_ctx.topic.date.dateAndTime()), 1)
    ]),
    (_ctx.topic.replyCount == 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Sin respuestas"))
      : _createCommentVNode("", true),
    (_ctx.topic.replyCount == 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, "1 respuesta"))
      : _createCommentVNode("", true),
    (_ctx.topic.replyCount > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.topic.replyCount) + " respuestas", 1))
      : _createCommentVNode("", true),
    (_ctx.topic.lastAnswer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createTextVNode(" Última respuesta por "),
          _createElementVNode("b", null, _toDisplayString(_ctx.topic.lastAnswer.author.username), 1),
          _createTextVNode(" el " + _toDisplayString(_ctx.topic.lastAnswer.date.dateAndTime()), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}