import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "flex-row"
}
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-button", {disabled: _ctx.isDisabled()}])
  }, [
    (!_ctx.loading && _ctx.lefticon)
      ? (_openBlock(), _createBlock(_component_svg_icon, _mergeProps({
          key: 0,
          class: "icon-left"
        }, _ctx.lefticon), null, 16))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.content)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                innerHTML: _ctx.content
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true),
    (!_ctx.loading && _ctx.righticon)
      ? (_openBlock(), _createBlock(_component_svg_icon, _mergeProps({
          key: 2,
          class: "icon-right"
        }, _ctx.righticon), null, 16))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_loader, {
          key: 3,
          size: 18,
          color: "white",
          backcolor: "var(--primary-color)"
        }))
      : _createCommentVNode("", true)
  ], 2))
}