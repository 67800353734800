import httpClient from "./httpClient"

export default {

    validate() {
        return new Promise((resolve, reject) => {

            const tk = httpClient.getToken();
            if (!tk) {
                return reject();
            }

            httpClient.get('auth.validate')
            .then(() => resolve(true))
            .catch(() => reject());

        })
    },

    login(credentials: any) {
        return new Promise((resolve, reject) => {
            httpClient.post('auth.login', credentials)
            .then(res => {
                if (res.token)
                    httpClient.setToken(res.token);

                resolve(!(!res.token));
            })
            .catch(err => reject())
        })
    }

}