import { defineComponent } from 'vue';
import { AppComponent, SafeArea, FormInput } from 'extracrew-ionic-lib';
import Profile from '@/models/Profile';

export default defineComponent({

    extends: AppComponent,

    components: {
        SafeArea,
        FormInput
    },

    data() {
        const data : {
            profile: Profile|null
            socials: any[]
        } = {
            profile: null,
            socials: []
        }

        return data;
    },

    created() {
        const params : any = {}
        if (this.$route.params.id) {
            params['user'] = this.$route.params.id
        }

        Profile.get(params)
        .then(pro => {
            this.profile = pro;
            
            if (pro.facebook) {
                this.socials.push({
                    icon: 'awesome/facebook-square',
                    color: '#3B5998',
                    link: 'https://www.facebook.com/' + pro.facebook
                })
            }

            if (pro.instagram) {
                this.socials.push({
                    icon: 'simple/instagram',
                    color: '#DD2A7B',
                    link: 'https://www.instagram.com/' + pro.instagram
                })
            }

            if (pro.twitter) {
                this.socials.push({
                    icon: 'zocial/twitter',
                    color: '#00ACEE',
                    link: 'https://www.twitter.com/' + pro.twitter
                })
            }

            if (pro.linkedin) {
                this.socials.push({
                    icon: 'elusive/linkedin',
                    color: '#0E76A8',
                    link: 'https://www.linkedin.com/' + pro.linkedin
                })
            }
        })
    },

    methods: { }

});