
export default class Term {

    ID: number = 0;
    name: string = '';
    slug: string = '';
    type: 'category'|'tag' = 'category';

    constructor(item: any, type: 'category'|'tag') {
        this.ID = item.ID;
        this.name = item.name;
        this.slug = item.slug;
        this.type = type;
    }

}