import config from './project.config';

let apiMap = {

    _base: config.api.url,
    _factory: null,

    auth: {
        login: '/login/',
        validate: '/authenticate/',
        profile: '/perfil/',
        contact: '/contact/'
    },
    posts: {
        home: '/home/',
        get: '/posts/',
        comments: '/comments/?id=:id'
    },
    forums: {
        list: '/forums/',
        get: '/forums/?id=:id',
        topics: '/topics/?forum=:forumId&offset=:offset',
        search: '/topics/?search=:search',
        topic: '/topics/?forum=:forum&id=:id',
        replies: '/replies/?topic=:id'
    },
    chat: {
        conversations: '/chat/',
        with: '/chat/?with=:userId'
    },
    schools: {
        list: '/schools/'
    },
    exams: {
        list: '/exams/',
        get: '/exams/?id=:id'
    },
    notifications: {
        list: '/notifications/'
    }

}

export { apiMap }