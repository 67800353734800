import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "toolbar-space"
}
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  key: 0,
  id: "toolbar-space"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      (_ctx.$slots.header)
        ? (_openBlock(), _createBlock(_component_ion_header, { key: 0 }, {
            default: _withCtx(() => [
              (!_ctx.isWeb)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "header")
            ]),
            _: 3
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_content, {
        "scroll-events": true,
        ref: "ioncontent",
        onIonScroll: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logScrolling($event)))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            id: !_ctx.disabled ? 'safe-area' : '',
            class: _normalizeClass({overlays: true || !_ctx.$slots.header && _ctx.overlays})
          }, [
            (!_ctx.$slots.header && !_ctx.isWeb)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "default")
          ], 10, _hoisted_2)
        ]),
        _: 3
      }, 512),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createBlock(_component_ion_footer, { key: 1 }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "footer")
            ]),
            _: 3
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}