<template>
    <div class="horizontal-scroll" :class="{'scroll-hidden': scrollbar == 'hidden', 'scroll-hover': scrollbar == 'hover'}">
        <slot />
    </div>
</template>

<style lang="scss">
.horizontal-scroll {
    display: flex;
    width: 100%;
    overflow-x: auto;

    > * {
        flex: none;
    }

    /* width */
    &::-webkit-scrollbar {
        height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #aaa;
    }

    &.scroll-hidden {
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: transparent;
        }

    }

    &.scroll-hidden {
        
        &::-webkit-scrollbar-thumb {
            background: transparent;

            &:hover {
                background: #aaa;
            }
        }

    }
}
</style>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: ['scrollbar']
})
</script>
