import { defineComponent } from 'vue';;
import Utils from '../../modules/utils';

export default defineComponent({

    name: 'tree-view',

    props: ['item', 
    'textKey', 
    'childrenKey', 
    'depth', 
    'onSelected', 
    'selected', 
    'activeColor', 
    'avoidFirst',
    'comparer'
    ],

    created() {
        if (this.depth) {
            this.depthLevel = this.depth;
        }
    },

    data() {
        let data : {
            expanded: boolean,
            depthLevel: number
        } = {
            expanded: false,
            depthLevel: this.avoidFirst ? -1 : 0
        }

        return data;
    },

    methods: {

        select() {
            if (!this.onSelected) return;
            this.onSelected(this.item);
            this.$forceUpdate();
        },

        getText() {
            if (!this.item) return '';
            return Utils.dotNotation(this.item, this.textKey ? this.textKey : 'text', '');
        },

        getChildren() {
            if (!this.item) return '';

            return Utils.dotNotation(this.item, this.childrenKey ? this.childrenKey : 'children', '');
        },

        isSelected() {

            let selected = this.selected;
            let item = this.item;

            if (!this.comparer) {
                return selected == item;
            }

            return this.comparer(item, selected);
        }

    }

});