import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "page-title" }
const _hoisted_2 = { id: "edit-profile-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_loader = _resolveComponent("loader")!
  const _component_form_input = _resolveComponent("form-input")!
  const _component_tempate = _resolveComponent("tempate")!
  const _component_safe_area = _resolveComponent("safe-area")!

  return (_openBlock(), _createBlock(_component_safe_area, null, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_svg_icon, {
          name: "heroicons-solid/chevron-left",
          class: "back",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.back()))
        })
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.profile)
          ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
          : (_openBlock(), _createBlock(_component_tempate, { key: 1 }, {
              default: _withCtx(() => [
                _createElementVNode("h2", null, _toDisplayString(_ctx.profile.username), 1),
                _createVNode(_component_form_input, {
                  type: "textarea",
                  label: "Descripción",
                  modelValue: _ctx.profile.description,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.profile.description) = $event))
                }, null, 8, ["modelValue"]),
                _createVNode(_component_form_input, {
                  label: "Nombre",
                  modelValue: _ctx.profile.name,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.profile.name) = $event))
                }, null, 8, ["modelValue"]),
                _createVNode(_component_form_input, {
                  label: "Apellidos",
                  modelValue: _ctx.profile.lastname,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.profile.lastname) = $event))
                }, null, 8, ["modelValue"]),
                _createVNode(_component_form_input, {
                  label: "Fecha de nacimiento",
                  modelValue: _ctx.age,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.age) = $event)),
                  type: "date"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_form_input, {
                  label: "Género",
                  modelValue: _ctx.profile.gender,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.profile.gender) = $event)),
                  type: "select",
                  options: [
                    { key: 'Hombre' },
                    { key: 'Mujer' }
                ]
                }, null, 8, ["modelValue"]),
                _createVNode(_component_form_input, {
                  label: "Población",
                  modelValue: _ctx.profile.city,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.profile.city) = $event))
                }, null, 8, ["modelValue"]),
                _createVNode(_component_form_input, {
                  label: "Código postal",
                  modelValue: _ctx.profile.postalcode,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.profile.postalcode) = $event))
                }, null, 8, ["modelValue"]),
                _createVNode(_component_form_input, {
                  label: "Ciudad",
                  modelValue: _ctx.profile.city,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.profile.city) = $event))
                }, null, 8, ["modelValue"]),
                _createVNode(_component_form_input, {
                  label: "Provincia",
                  modelValue: _ctx.profile.province,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.profile.province) = $event))
                }, null, 8, ["modelValue"]),
                _createVNode(_component_form_input, {
                  label: "País",
                  modelValue: _ctx.profile.country,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.profile.country) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }))
      ])
    ]),
    _: 1
  }))
}