import { defineComponent } from 'vue';
import SliderSelect from '../slider-select/slider-select.vue';
import Toggle from '../toggle/toggle.vue';
import intlTelInput from 'intl-tel-input';
import countryData from 'countries-and-timezones';
import Multiselect from '../multiselect/multiselect.vue';

export default defineComponent({

    components: { SliderSelect, Toggle, Multiselect },

    props: [ 
        'modelValue',
        'type', 
        'label', 
        'placeholder', 
        'options',
        'rows', 
        'length',
        'max',
        'min',
        'onChange',
        'inverted',
        'error',
        'onPressEnter',
        'disabled',
        'readonly',
        'onFocus',
        'suggestions',
        'suggestionsKey',
        'onSuggestionSelected',
        'required',
        'labelOver',

        // Radio
        'option',
        'labelClickable',
 
        // Toggle
        'size',

        // Phone
        'initialCountry',

        // Multiselect
        'multiple',
        'separator',
        'comparer',

        // Editor
        'mode'
    ],

    data: function() {

        let data : {
            character_count: number,
            displayContent: boolean,
            iti: any,
            isFocused: boolean
        } = {
            character_count: 0,
            displayContent: false, // for passwords,
            iti: null,
            isFocused: false
        }
        return data;
    },

    created() {
        if (this.length && this.modelValue && typeof this.modelValue == 'string') {
            this.character_count = this.modelValue.length;
        }
    },

    mounted() {
        if (this.type == 'phone') {

            let options : any = {
                separateDialCode: true,
                preferredCountries: ["es", "fr", "gb", "us", "it", "de"]
            }

            if (this.initialCountry) {
                options['initialCountry'] = this.initialCountry;
            }

            if (this.placeholder) {
                options['customPlaceholder'] = (country: any, data: any) => {
                    return this.placeholder;
                }
            }

            this.iti = intlTelInput((this.$refs.phoneInput as any), options);

            this.iti.telInput.addEventListener('countrychange', () => {
                this.phoneChange();
            });

            this.iti.telInput.addEventListener('keyup', () => {
                this.phoneChange();
            });

        }
    },

    methods: {

        optionsForSelect() {
            if (this.options) return this.options;

            let list : {label: string, value: string}[] = [];

            if (this.type == 'country') {

                let countries = countryData.getAllCountries();

                Object.keys(countries).forEach(k => {
                    list.push({
                        label: countries[k].name,
                        value: countries[k].name
                    });
                });
            }

            return list;
        },

        phoneChange() {
            let number = this.iti.telInput.value;
            let data = this.iti.getSelectedCountryData();

            let result = {
                number: number,
                prefix: data.dialCode,
                country: data.iso2
            }

            this.$emit('update:modelValue', result.prefix + result.number);

            if (this.onChange) {
                this.onChange(result);
            }

            this.$emit('change', result);
        },

        isRadioActive() {
            return this.option.value == this.modelValue;
        },

        emit(v: any) {
            this.$emit('update:modelValue', v);

            if (this.length) {
                this.character_count = v.length;
            }

            if (this.onChange) {
                this.onChange(v);
            }

            this.$emit('change', v);
        },

        clear() {
            this.emit('');
        },

        onSubmit() {
            this.$emit('pressEnter', this.modelValue);

            if (this.onPressEnter) {
                this.onPressEnter(this.modelValue);
            }
        },

        focused() {
            this.isFocused = true;
            this.$emit('focus', this);

            if (this.onFocus) {
                this.onFocus(this);
            }
        },

        selectSuggestion(s: any) {

            if (!this.onSuggestionSelected) return;
            this.onSuggestionSelected(s.item);
        },

        changePasswordVisibility() {
            this.displayContent = !this.displayContent;
        },

        focus() {

            if (this.type == 'password') {
                (this.$refs.passwordInput as any).focus();
            }

            else if (this.type == 'textarea') {
                (this.$refs.textareaInput as any).focus();
            }

            else if (!this.type || ['text', 'number', 'email', 'date', 'color'].includes(this.type)) {
                (this.$refs.normalInput as any).focus();
            }

        }

    }

})