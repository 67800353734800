import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mask"
}
const _hoisted_2 = {
  key: 1,
  class: "title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: "post-card bgimage",
    style: _normalizeStyle({
        'background-image': 'url(' + _ctx.post.image + ')',
        'background-size': _ctx.post.title ? 'cover' : 'contain'
    }),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.to('/post/' + _ctx.post.ID)))
  }, [
    (_ctx.post.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.post.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.post.title), 1))
      : _createCommentVNode("", true)
  ], 4))
}