import httpClient from "@/modules/httpClient";
import { Time, AppManager } from "extracrew-ionic-lib";

export default class Profile {

    public: any = {}

    mine: boolean = false;

    username: string = '';
    image: string = '';
    age: Time = new Time();
    city: string = '';
    company: string = '';
    country: string = '';
    description: string = '';
    email: string = '';
    facebook: string = '';
    gender: 'male'|'female' = 'male';
    instagram: string = '';
    lastname: string = '';
    linkedin: string = '';
    name: string = '';
    phone: string = '';
    postalcode: string = '';
    profession: string = '';
    province: string = '';
    twitter: string = '';
    website: string = '';

    constructor(item?: any) {
        if (!item) return;

        if (item.age) {
            this.age = new Time(item.age, 'YYYY-MM-DD');
        }

        this.username = item.username;
        this.mine = item.mine;

        if (item.image) {
            this.image = AppManager.get().api.url.replace('/api', item.image);
        }

        const props = [
            'city',
            'company',
            'country',
            'description',
            'email',
            'facebook',
            'gender',
            'instagram',
            'lastname',
            'linkedin',
            'name',
            'phone',
            'postalcode',
            'profession',
            'province',
            'twitter',
            'website'
        ]

        for(let p of props) {
            if (item[p])
                (this as any)[p] = item[p];

            if (item['public_'+p]) {
                this.public[p] = item['public_' + p];
            }
        }

    }

    get fullname() {
        return this.name + ' ' + this.lastname;
    }

    get profileProps() : string[] {
        const p = [
            'gender',
            'profession',
            'company',
            'country',
            'province',
            'city',
            'phone',
            'website'
        ]
        const props : string[] = []
        
        for(let pr of p) {
            if (this.public[pr]) {
                props.push(pr);
            }
        }
        return props;
    }

    static get(params?: any) : Promise<Profile> {
        return new Promise((resolve, reject) => {
            httpClient.get('auth.profile', params)
            .then(response => {
                resolve(new Profile(response));
            });
        })
    }

}