import { SafeArea, Time } from 'extracrew-ionic-lib';
import { defineComponent } from 'vue';
import { AppComponent, CircleImage } from 'extracrew-ionic-lib';
import Back from '@/components/back/back.vue';
import { Conversation } from '@/models/Conversation';
import User from '@/models/User';

export default defineComponent({

    name: 'chat',
    extends: AppComponent,

    components: {
        SafeArea,
        Back,
        CircleImage
    },

    data() {
        const data : {
            user: User|null,
            messages: any[],
            message: string
        } = {
            user: null,
            messages: [],
            message: ''
        }

        return data;
    },

    created() {
        Conversation.get(this.$route.params.userId)
        .then(data => {
            this.user = data.user;
            this.messages = data.messages.items;
        })
    },

    methods: {
        sendMessage() {
            if (!this.message) return;

            Conversation.write({
                to: this.$route.params.userId,
                content: this.message
            });

            this.messages.unshift({
                content: this.message,
                isMine: true,
                date: Time.instance().format('DD/MM/YYYY HH:mm')
            });

            this.message = '';
        }
    }

});