import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = {
  key: 0,
  class: "slider-select-dropdown"
}
const _hoisted_3 = ["innerHTML", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["slider-select", {open: _ctx.open, inverted: _ctx.inverted, disabled: _ctx.disabled}])
  }, [
    _createElementVNode("div", {
      class: "slider-select-bullet",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDropdown()))
    }, [
      _createElementVNode("div", {
        class: "slider-select-selected",
        innerHTML: _ctx.selected ? (_ctx.selected.content ? _ctx.selected.content : _ctx.selected.value) : (_ctx.placeholder ? _ctx.placeholder : '')
      }, null, 8, _hoisted_1),
      _createVNode(_component_svg_icon, {
        name: "chevron-down",
        class: "text-primary"
      })
    ]),
    (_ctx.options)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "slider-select-option",
              key: index,
              innerHTML: option.content ? option.content : option.value,
              onClick: ($event: any) => (_ctx.select(option))
            }, null, 8, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}