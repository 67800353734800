import { defineComponent } from 'vue';
import FormInput from '../form-input/form-input.vue';
import CircleImage from '../circle-image/circle-image.vue';
import translate from '../../modules/translator';
import Utils from '../../modules/utils';

export default defineComponent({

    components: { FormInput, CircleImage },

    props: ['form'],

    data() {
        let data : {
            cols: any[]|null
        } = {
            cols: null
        }
        return data;
    },

    created() {
        if (!this.form) return;

        this.cols = [];

        for(let col of this.form) {

            if (!col.fields) continue;

            let c : any = {
                width: col.width ? col.width : '100%',
                fields: []
            }

            for(let field of col.fields) {

                let model : any = {};
                if (field.model) {
                    model = field.model;
                } else {
                    model[field.name] = field.value ? field.value : '';
                }

                let f : {
                    name: string,
                    props: any,
                    type: string,
                    model: any,

                    // For file
                    fileSrc: any
                } = {
                    model: model,
                    name: field.name ? field.name : '',
                    type: field.type ? field.type : 'text',
                    props: {},

                    // For file
                    fileSrc: null
                }

                Object.keys(field)
                .forEach((k: string) => {
                    if (['name', 'modelValue', 'model'].includes(k)) {
                        return;
                    }

                    f.props[k] = field[k];
                });

                c.fields.push(f);

            }

            this.cols.push(c);
        }
    },

    methods: {

        $t(key: string) {
            return translate.get(key);
        },

        classForColumn(col: any) {
            
            if (!col.width) return {}
            if (!col.width.includes('%')) return {}

            let number = col.width.replace('%', '');
            let cl = 'col-md-' + number + '-p';

            let obj : any = {}
            obj[cl] = true;
            return obj;

        },

        fileSelected(field: any, file: any) {
            field.model[field.name] = file;
            if (file) {
                Utils.fileToSrc(file)
                .then((src: any) => {
                    field.fileSrc = src;
                });
            } else {
                field.fileSrc = null;
            }
        },

        gather() {
            if (!this.form) return {};

            let data: any = {}

            for(let col of this.cols!) {

                if (!col.fields) continue;

                for(let field of col.fields) {

                    if (!field.name) continue;

                    data[field.name] = field.model[field.name];

                }
            }

            return data;
        }

    }

});