import { defineComponent } from 'vue';
import { AppComponent, EventListener, Carousel, Utils } from 'extracrew-ionic-lib';
import School from '@/models/School';
import Exam from '@/models/Exam';

export default defineComponent({

    extends: AppComponent,

    components: {
        Carousel
    },

    data() {
        const data : {
            featured: School[]|null
            schools: School[]|null,
            exams: {
                exams: Exam[],
                categories: any[]
            }|null,
            tab: 'schools'|'exams'
        } = {
            featured: null,
            schools: null,
            exams: null,
            tab: 'exams'
        }

        return data;
    },

    created() {
        School.list()
        .then(response => {
            this.featured = response.schools.filter((item: any) => item.featured)
            this.schools = response.schools.filter((item: any) => !item.featured)

            if (this.featured.length > 0) {
                this.featured = Utils.shuffleArray(this.featured);
            }
        });

        EventListener.on('exams', 'updateList', () => {
            Exam.list()
            .then(exams => {
                this.exams = exams;
            });
        });
        EventListener.trigger('exams');

    },

    methods: {
        examClass(e: Exam) {
            const cl: any = {}
            cl[e.status] = true;
            return cl;
        }
    }

});