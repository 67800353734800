
export default class EventListener {

    static events : any = {}

    static waiters : any = {}

    static on(event: string, action: string, callback: (param?: any) => void) {

        if (!this.events[event]) {
            this.events[event] = {}
        }

        this.events[event][action] = callback;

        if (this.waiters[event]) {
            for(let waiter of this.waiters[event]) {
                callback(waiter);
            }
            this.waiters[event] = null;
            delete this.waiters[event];
        }

    }

    static trigger(event: string, param?: any) {

        if (!this.events[event]) {

            if (!this.waiters[event]) {
                this.waiters[event] = [];
            }

            this.waiters[event].push(param);
            return;
        }

        Object.keys(this.events[event]).forEach( (action: string) => {
            this.events[event][action](param);
        });

    }

}