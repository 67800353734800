import { defineComponent } from 'vue';

export default defineComponent({

    props: [ 'onLoadMore', 'threshold', 'list', 'emptyMessage', 'reverse', 'scroller' ],

    data() {
        let data : {
            loading: boolean,
            finished: boolean,
            scrollPosition: number
        } = {
            loading: false,
            finished: false,
            scrollPosition: 0
        }

        return data;
    },

    created() {
        this.load();

        window.addEventListener('scroll', () => {
            this.handleScroll(null);
        });

        if (this.scroller) {
            this.scroller.addEventListener('scroll', () => {
                this.handleScroll(null);
            })
        }
    },

    methods: {

        getScroller() {
            return this.scroller ? this.scroller : (this.$refs.container as any);
        },

        scrollTo(position: number) {
            this.getScroller().scrollTop = position;
        },

        detectScroll() {

            let position = window.scrollY + window.innerHeight;
            let mark = (this.$refs.mark as any);
            let container = this.getScroller();
            let threshold = this.threshold ? this.threshold : 50;

            this.scrollPosition = container.scrollTop;
            this.$emit('scrolling', this.scrollPosition);

            if (!this.reverse) {
                if (position + container.scrollTop >= mark.offsetTop - threshold) {
                    return true;
                }
            } else {
                if (container.scrollTop <= mark.offsetTop + threshold) {
                    return true;
                }
            }

            return false;

        },

        handleScroll(e:any) {

            if (!this.onLoadMore) return;
            if (this.loading) return;
            if (this.finished) return;

            // New method
            if (this.detectScroll()) {
                this.load();
            }

            // Old method
            /*if (Utils.scrollBottomReached(e, this.threshold)) {
                this.load();
            }*/

        },

        reset() {
            this.loading = false;
            this.finished = false;
            this.load();
        },

        load() {
            if (!this.onLoadMore) {
                console.log("Infinite Scroll needs an onLoadMore function");
                return;
            }

            this.loading = true;

            this.onLoadMore({
                stop: (finish = false) => {
                    this.loading = false;
                    this.finished = finish;
                }
            });
        }
    }

});