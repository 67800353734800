import httpClient from "@/modules/httpClient";
import { Time } from "extracrew-ionic-lib";
import User from "./User";

export class Conversation {

    ID: number = 0;
    lastDate: Time = new Time();
    seen: boolean = false;
    with: User|null = null;

    constructor(item: any) {
        this.ID = item.ID;
        this.seen = item.seen;
        this.lastDate = Time.instance(item.lastDate, 'DD/MM/YYYY HH:mm');
        this.with = new User(item.with);
    }

    static list() : Promise<Conversation[]> {

        return new Promise(resolve => {

            httpClient.get('chat.conversations')
            .then(res => {

                const convs = res.map((item: any) => {
                    return new Conversation(item);
                });

                resolve(convs);

            })
            .catch(err => resolve([]))

        })

    }

    static get(userId: any) : Promise<{user: User, messages: any}> {
        return new Promise(resolve => {
            
            httpClient.get(['chat.with', {userId}])
            .then(res => {

                resolve({
                    messages: res.messages,
                    user: new User(res.user)
                });

            })

        });
    }

    static write(params: any) : Promise<any> {
        return new Promise((resolve, reject) => {
            httpClient.post('chat.conversations', params)
            .then(resolve)
            .catch(reject);
        });
    }

}