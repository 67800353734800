
import { defineComponent } from 'vue';;

export default defineComponent({
    props: ['size'],

    methods: {
        getStyle() {
            let s = this.size ? this.size : 20;

            return {
                width: s + 'px',
                height: s + 'px',
                cursor: 'pointer'
            }
        },
        doClick() {
            this.$emit('click');
        }
    }
});
