import { defineComponent } from 'vue';;

export default defineComponent({
  components: { },

  props: ['options', 'placeholder', 'modelValue', 'inverted', 'onChange', 'disabled', 'comparer'],

  data() {

    let data : {
      open: boolean,
      selected: any
    } = {
      open: false,
      selected: null
    }

    return data
  },

  created() {
    this.setDefault();

    document.body.addEventListener('click', () => {
        if (!this.open) return;

        setTimeout(() => {
            this.open = false;
        }, 50);
    }, true); 
  },
  updated() {
    this.setDefault();
  },

  methods: {
    select(option: any) {
      this.selected = (option.value === null) ? null : option;
      this.$emit('update:modelValue', option.value);
      this.open = false;

      this.$emit('change', option.value);

      if (this.onChange) {
        this.onChange(option.value);
      }
    },

    setDefault() {
      if (this.modelValue && this.options) {
        for(let op of this.options) {

          let compare = this.comparer ? this.comparer : (a: any, b: any) => a.id == b.id;

          if (compare(op.value, this.modelValue)) {
            this.selected = op;
            this.$emit('update:modelValue', op.value);
            return;
          }
        }
      }
    },

    openDropdown() {
      if (this.disabled) return;
      this.open = !this.open;
    }
  }

})