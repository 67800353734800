<template>
    <div class="loading-card flash-effect">
        <loader/>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
})
</script>

<style lang="scss">
.loading-card {
    border-radius: 5px;
    background-color: rgb(236, 236, 236);
    padding: 30px 0;
    margin: 20px 0;
}
</style>