import { defineComponent } from 'vue';
import { AppComponent, SafeArea } from 'extracrew-ionic-lib';
import School from '@/models/School';

export default defineComponent({

    name: 'school',
    extends: AppComponent,

    components: {
        SafeArea
    },

    data() {
        const data : {
            school: School|null
        } = {
            school: null
        }

        return data;
    },

    created() {
        School.get(Number(this.$route.params.id))
        .then(s => {
            this.school = s;
        })
    },

    methods: { }

});