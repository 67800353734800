import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "searchbar" }
const _hoisted_2 = ["placeholder", "value"]
const _hoisted_3 = {
  key: 0,
  class: "suggestions"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_svg_icon, {
      name: "search",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.search()))
    }),
    _createElementVNode("input", {
      placeholder: _ctx.placeholder ? _ctx.placeholder : _ctx.$t('generic.search'),
      onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.search()), ["enter"])),
      value: _ctx.value,
      onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onInput($event.target.value)))
    }, null, 40, _hoisted_2),
    (_ctx.suggestions && _ctx.suggestions.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.suggestions, (suggestion) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "suggestion",
              key: suggestion,
              onClick: ($event: any) => (_ctx.selectSuggestion(suggestion))
            }, _toDisplayString(suggestion), 9, _hoisted_4))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}