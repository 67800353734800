import { defineComponent } from 'vue';
import 'swiper/scss';
import '@ionic/vue/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper';

export default defineComponent({

    props: ['items', 'autoplay', 'options'],

    components: {
        Swiper,
        SwiperSlide
    },

    data() {
        const data : {
            modules: any[]
        } = {
            modules: [Autoplay]
        }

        return data;
    },

    methods: {
        getOptions() {
            // https://swiperjs.com/swiper-api#parameters
            if (this.options) return this.options;

            return {
                slidesPerView: 1,
                loop: true
            }
        }
    }

});