import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "back flex-row",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.back()))
  }, [
    _createVNode(_component_svg_icon, { name: "chevron-left" }),
    _createElementVNode("div", null, _toDisplayString(_ctx.$t('generic.back')), 1)
  ]))
}