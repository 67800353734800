import Utils from './utils';

export default class Nav {

    private static data : any = {}

    static clear() {
        this.data = {}
    }

    static set(name: string, data: any) {
        this.data[name] = data;
    }

    static get(key: string) {
        return Utils.dotNotation(this.data, key, '');
    }

}