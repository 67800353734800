import Chart from 'chart.js/auto'
import { defineComponent } from 'vue';

export default defineComponent({

    props: ['id', 'size', 'type', 'data', 'thickness', 'options', 'dataset', 'datasets'],

    data: function() {

        return {
        };
    },

    methods: {
        randomColor() {

            let rand = () => {
                return Math.round(Math.random() * 255);
            }
        
            let r = rand();
            let g = rand();
            let b = rand();

            // Lower saturation
            let m = (r+g+b) / 3;

            r = (r + (m-r)*0.7) *1.08;
            g = (g + (m-g)*0.7) *1.08;
            b = (b + (m-b)*0.7) *1.08;

            return "rgb(" + r + ", " + g + ", " + b + ")";

        }
    },

    mounted() {
        var canvas: any = this.$refs.canvas;
        let ctx = canvas.getContext('2d');

        if (!this.data && !this.datasets) return;


        let labels = [];
        let values = [];
        let colors = [];

        if (this.data) {
            for(let row of this.data) {

                let rand = () => {
                    return Math.round(Math.random() * 255);
                }

                labels.push(row.label);
                values.push(row.value);
                colors.push(row.color ? row.color : this.randomColor());
            }
        }

        let options : any = {
            devicePixelRatio: 3,
            responsive: true,
            plugins: {
                legend: {
                    display: false
                }
            },
            cutout: this.thickness ? this.thickness : 50
        }

        let dataset : any = {
            label: '',
            data: values,
            backgroundColor: colors,
            borderWidth: 0
        }

        if (this.options) {
            Object.keys(this.options)
            .forEach((k: string) => {
                options[k] = this.options[k];
            })
        }

        if (this.dataset) {
            Object.keys(this.dataset)
            .forEach((k: string) => {
                dataset[k] = this.dataset[k];
            })
        }

        new Chart(ctx, {
            type: this.type ? this.type : 'doughnut',
            options: options,
            data: {
                labels: labels,
                datasets: this.datasets ? this.datasets : [dataset]
            }
        });
    }

})