import Moment from 'moment-timezone';
import timezone from 'countries-and-timezones';
import AppManager from './manager';

export default class Time {

    _moment: Moment.Moment;

    static instance(item?: any, format?: string, correctTimezone?: boolean) {
        return new Time(item, format, correctTimezone);
    }

    constructor(item?: any, format?: string, correctTimezone?: boolean) {

        var userLang = AppManager.defaultLanguage();
        Moment.locale(userLang);

        let it = item;
        if (!it) {
            var now = new Date();
            var date = now.getFullYear()+'-'+(now.getMonth()+1)+'-'+ now.getDate();
            var time = now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
            it = date+' '+time;
        }

        let f = format;
        if (!f) {
            if (it.includes('Z')) {
                f = "YYYY-MM-DDTHH:mm:ssZ";
            } else if (it.includes(':')) {
                f = 'YYYY-MM-DD HH:mm:ss';
            } else {
                f = 'YYYY-MM-DD';
            }
        }

        this._moment = Moment(it, f);
        if (correctTimezone) {
            let tz = timezone.getTimezone(Moment.tz.guess());
            if (tz) {
                this._moment = this._moment.add(tz.dstOffset / 60, 'hours');
            }
        }

    }

    dateObject() {
        return this._moment.toDate();
    }

    format(format: string) {
        return this._moment.format(format);
    }

    dateString() {
        return this._moment.format("dddd DD MMM - HH:mm");
    }

    date() {
        return this._moment.format("DD/MM/YYYY");
    }

    timestamp() {
        return parseInt(this._moment.format("X"));
    }

    datetime() {
        return this._moment.format("YYYY-MM-DD HH:mm:ss");
    }

    time() {
        return this._moment.format("HH:mm");
    }

    dateAndTime() {
        return this._moment.format("DD/MM/YYYY HH:mm");
    }

    calendar() {
        return this._moment.format("ddd D MMMM YYYY");
    }

    ago() {
        return Moment.duration( Moment(new Date()).diff(this._moment) ).asSeconds()
    }

    get(what: string) {
        let f = "YYYY";
        if (what == "second") f = "ss";
        else if (what == "minute") f = "mm";
        else if (what == "hour") f = "HH";
        else if (what == "day") f = "DD";
        else if (what == "month") f = "MM";
        return parseInt(this._moment.format(f)) + "";
    }

    static timeToDate(value: string) {
        let d : any = new Date();
        let parts = value.split(":");
        d.setHours = parseInt(parts[0]);
        d.setMinutes = parseInt(parts[1]);
        return d;
    }

    add(days: number) {
        let d = this._moment.toDate();
        d.setDate(d.getDate()+days);

        this._moment = Moment(d);
        return this;
    }

    sub(days: number) {
        return this.add(-days);
    }

    secondsPassed(time?: Time) {
        var duration = Moment.duration((time ? time : Time.now())._moment.diff(this._moment));
        return duration.asSeconds();
    }

    dayOfWeek() {
        let d = this.dateObject().getDay();
        d -= 1;
        if (d < 0) d = 6;
        return d;
    }

    dayOfMonth() {
        return this.dateObject().getDate();
    }

    dayName() {
        let d = this.dayOfWeek();

        let days: any = {
            0: "monday",
            1: "tuesday",
            2: "wednesday",
            3: "thursday",
            4: "friday",
            5: "saturday",
            6: "sunday",
        }
        return days[d];
    }

    getMonday() {
        let d : Date = new Date();
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        d = new Date(d.setDate(diff));
        return new Time(d);
    }

    getSunday() {
        let d : Date = new Date();
        var day = d.getDay(),
            diff = d.getDate() + (6 - day) + (day == 0 ? -6:1); // adjust when day is sunday
        d = new Date(d.setDate(diff));
        return new Time(d);
    }

    static now() {
        return new Time();
    }

    static todayAt(hour: number, minute?: number) {
        let d = new Date();
        d.setHours(hour, minute);
        return Time.fromDate(d);
    }

    static fromDate(d: Date) {

        ///// FORMAT DATE AS STRING

        let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        let str = [year, month, day].join('-');

        //////////
        return new Time(str, "YYYY-MM-DD");
    }

    static fromTimestamp(t: number) {
        return new Time(t, "X");
    }

    copy() {
        return new Time(this.datetime());
    }

    isAfter(other: Time) {
        return this._moment.isAfter(other._moment);
    }

    isPast() {
        return !this.isAfter(Time.now());
    }

}