import { defineComponent } from 'vue';
import { AppComponent, CircleImage, SafeArea } from 'extracrew-ionic-lib';
import { Conversation } from '@/models/Conversation';

export default defineComponent({

    extends: AppComponent,

    components: {
        CircleImage,
        SafeArea
    },

    data() {
        const data : {
            conversations: Conversation[] | null
        } = {
            conversations: null
        }

        return data;
    },

    created() {
        Conversation.list()
        .then(convs => {
            this.conversations = convs;
        });
    },

    methods: { }

});