import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "forum" }
const _hoisted_2 = { class: "search" }
const _hoisted_3 = {
  key: 1,
  class: "list"
}
const _hoisted_4 = {
  key: 0,
  class: "forum-title forum-box"
}
const _hoisted_5 = { class: "forum-sections" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "name" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 2,
  class: "list"
}
const _hoisted_11 = {
  key: 3,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_bar = _resolveComponent("search-bar")!
  const _component_loader = _resolveComponent("loader")!
  const _component_topic_card = _resolveComponent("topic-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_search_bar, {
        modelValue: _ctx.search,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
        onStop: _cache[1] || (_cache[1] = ($event: any) => (_ctx.doSearch()))
      }, null, 8, ["modelValue"])
    ]),
    (!_ctx.forums || (_ctx.search && !_ctx.searchResult))
      ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.forums && !_ctx.search)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.forums, (f) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "forum-item",
              key: f.ID
            }, [
              false
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(f.name), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(f.sections, (s) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "section forum-box",
                    key: s.ID,
                    onClick: ($event: any) => (_ctx.to('/forums/' + s.ID))
                  }, [
                    (s.icon)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          class: "section-icon",
                          src: s.icon
                        }, null, 8, _hoisted_7))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_8, _toDisplayString(s.name), 1),
                      _createElementVNode("div", {
                        class: "description lines2",
                        innerHTML: s.description
                      }, null, 8, _hoisted_9)
                    ])
                  ], 8, _hoisted_6))
                }), 128))
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.search && _ctx.searchResult && _ctx.searchResult.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchResult, (topic) => {
            return (_openBlock(), _createBlock(_component_topic_card, {
              key: topic.ID,
              topic: topic
            }, null, 8, ["topic"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.search && _ctx.searchResult && _ctx.searchResult.length == 0)
      ? (_openBlock(), _createElementBlock("p", _hoisted_11, "No se han encontrado resultados."))
      : _createCommentVNode("", true)
  ]))
}