import httpClient from "@/modules/httpClient"

export default class Notification {

    ID: number = 0;
    date: string = '';
    title: string = '';
    object: number = 0;
    type: string = '';

    constructor(item?: any) {
        this.ID = item.ID;
        this.date = item.date;
        this.title = item.content;
        this.object = item.object;
        this.type = item.type;
    }

    openLink(nav: (route: string) => any) {
        if (['reply_my_topic', 'reply_topic'].includes(this.type)) {
            nav('/forums/0/topic/' + this.object);
        }
        else if (this.type == 'chat') {
            nav('/conversations');
        }
    }

    static list() : Promise<Notification[]> {
        return new Promise(resolve => {

            httpClient.get('notifications.list')
            .then(response => {
                resolve(response.map((item: any) => {
                    return new Notification(item);
                }))
            })

        })
    }

}