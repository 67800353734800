import helper from "@/modules/helper";
import { Time } from "extracrew-ionic-lib";
import User from "./User";

export default class Reply {

    ID: number = 0;
    content: string = '';
    author?: User;
    date:Time = new Time();

    constructor(item?: any, dateFormat?: string) {
        if (!item) return;

        this.ID = item.ID;
        this.content = helper.fixHtml(item.content);
        if (item.author) {
            this.author = new User(item.author);
        }
        this.date = Time.instance(item.date, dateFormat ? dateFormat : undefined);
    }

}