import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "modal-alert" }
const _hoisted_2 = {
  key: 0,
  class: "closer"
}
const _hoisted_3 = { class: "flex-grow flex-center flex-col" }
const _hoisted_4 = {
  key: 1,
  class: "buttons"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.cancelable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_svg_icon, {
            name: "x",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_svg_icon, {
            key: 0,
            class: "alert-icon",
            name: _ctx.icon
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      _createElementVNode("p", null, _toDisplayString(_ctx.text), 1),
      (_ctx.buttonLeft || _ctx.buttonRight)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.buttonLeft)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "button-left",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clickButton(_ctx.buttonLeft)))
                }, _toDisplayString(_ctx.buttonLeft.text), 1))
              : _createCommentVNode("", true),
            (_ctx.buttonRight)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "button-right",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clickButton(_ctx.buttonRight)))
                }, _toDisplayString(_ctx.buttonRight.text), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}