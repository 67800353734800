import { defineComponent } from 'vue';
import { AppComponent, SafeArea, FormInput } from 'extracrew-ionic-lib';
import Profile from '@/models/Profile';

export default defineComponent({

    name: 'edit-profile',
    extends: AppComponent,

    components: {
        SafeArea,
        FormInput
    },

    data() {
        const data : {
            profile?: Profile,
            age: string
        } = {
            profile: undefined,
            age: ''
        }

        return data;
    },

    created() {
        Profile.get()
        .then(pro => {
            this.profile = pro;
            this.age = pro.age.format('DD/MM/YYYY')
        })
    }

});