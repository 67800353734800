
import App from './App.vue'
import router from './router';
import './assets/ionic';
import { AppManager } from 'extracrew-ionic-lib';
import config from './project.config';
import locales from './assets/translations/locales';

AppManager.init(App, router, config, locales)
.then((app: any) => {

});