import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createBlock(_component_swiper, _mergeProps({
    class: "carousel",
    modules: _ctx.modules,
    autoplay: _ctx.autoplay
  }, _ctx.getOptions()), {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_swiper_slide, {
          key: item.id
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "item", _normalizeProps(_guardReactiveProps(item)))
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 3
  }, 16, ["modules", "autoplay"]))
}