import { defineComponent } from 'vue';
import { AppComponent } from 'extracrew-ionic-lib';
import ProfileView from '@/components/profile-view/profile-view.vue';

export default defineComponent({

    name: 'profile',
    extends: AppComponent,

    components: {
        ProfileView
    }

});