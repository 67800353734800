import { defineComponent } from 'vue';

export default defineComponent({

    props: {
        scroller: {
            type: Function
        },
        // :scroller="() => ref('scroller')"
        background: {
            type: String
        },
        blur: {
            type: Number
        },
        height: {
            type: Number,
            default: 300
        },
        maskOpacity: {
            type: Number,
            default: 0
        },
        flexibleWidth: {
            type: String,
            default: '100%'
        },
        flexiblePadding: {
            type: Number,
            default: 15
        },
        useMask: {
            type: Boolean,
            default: false
        },
        useGradient: {
            type: Boolean,
            default: false
        },
        overlayTopAndFlexible: {
            type: Boolean,
            default: true
        }
    },


    data() {
        let data : {
            currentScroll: number,  // Posición del scroll en el que estamos

            bottomOpacity: number,  // 0 -> 100% en el progreso de la animación

            topHeight: number,      // Altura del apartado de arriba (slot top)
            bottomHeight: number,   // Altura del apartado de abajo (slot bottom)
            flexibleHeight: number, // Altura del apartado del medio (slot, flexible)
            flexWidth: number,  // Ancho del apartado del medio
            containerWidth: number  // Ancho de la pantalla,
            collapsedHeight: number // Ancho que debería tener al estar cerrado
        } = {
            currentScroll: 0,

            bottomOpacity: 1,

            topHeight: 50,
            bottomHeight: 50,
            flexibleHeight: 50,
            flexWidth: 50,
            containerWidth: 1000,
            collapsedHeight: 50
        }

        return data;
    },

    mounted() {
        // Obtener tamaños que vamos a usar a partir de los bloques que ha
        // introducido el programador.
        this.getSizes();

        this.setupScroller();
    },

    methods: {

        setupScroller() {
            if (!this.scroller) return;
            let sc = this.scroller();
            if (!sc) {
                setTimeout(() => {
                    this.setupScroller();
                }, 50);
                return;
            }

            sc.addEventListener('scroll', ($e: any) => {
                this.scroll({
                    detail: {
                        currentY: $e.target.scrollTop
                    },
                    element: $e.target
                });
            });
        },

        getSizes() {
            let test = (this.$refs.top as any).offsetHeight;

            // Si todavía no han cargado los elementos de pantalla, darle
            // unos milisegundos más.
            if (test == 0) {
                setTimeout(() => {
                    this.getSizes();
                }, 25);
            } else {
                this.topHeight = (this.$refs.top as any).offsetHeight;
                this.bottomHeight = (this.$refs.bottom as any).offsetHeight;
                this.flexibleHeight = (this.$refs.flexible as any).offsetHeight;
                this.flexWidth = (this.$refs.flexible as any).offsetWidth;
                this.containerWidth = (this.$refs.container as any).offsetWidth;

                if (this.overlayTopAndFlexible)
                    this.collapsedHeight = this.topHeight > this.flexibleHeight ? this.topHeight : this.flexibleHeight;
                else
                    this.collapsedHeight = this.topHeight + this.flexibleHeight;
            }
        },

        // Al hacer scroll
        scroll($e: {detail: any, element: any}) {
            this.currentScroll = $e.detail.currentY;

            // La altura que tiene disponible la parte flexible es la de todo
            // el componente - la altura del apartado top
            let flexibleHeight = this.height - this.collapsedHeight;

            // Y esto es el espacio que queda restante hasta arriba
            let resting = this.currentScroll >= flexibleHeight ? 0 : flexibleHeight - this.currentScroll;

            // El % de progreso que llevamos es (lo que queda / total recorrido)
            this.bottomOpacity = resting / flexibleHeight;
        },

        // Posición vertical en la que poner el contenido flexible
        flexibleTop() {

            if (this.bottomOpacity == 0) 
            return this.overlayTopAndFlexible ?
                0 : this.topHeight;

            // = altura inicial - (altura de parte inferior bottom + flexible)
            let top = this.height - this.bottomHeight - this.flexibleHeight;
            return top;
        },

        // Posición horizontal en que poner el contenido flexible
        flexibleLeft() {
            // Si vamos por menos de la mitad, mantenerlo a la izquierda
            if (this.bottomOpacity > 0.5) return this.flexiblePadding;

            // A partir de medio recorrido, ir moviendolo hacia el centro
            // El límite (max) es cuando ya está centrado
            let max = this.containerWidth / 2 - this.flexWidth / 2 - this.flexiblePadding;

            // Left = max * % progreso
            let left = this.flexiblePadding + max * (1 - (this.bottomOpacity * 2) );
            return left;
        },

        // Opacidad para la animación
        layerOpacity() {
            let start = 1/3;
            let end = 1 - (this.maskOpacity ? this.maskOpacity : 0);
            let diff = end - start;
            return start + diff * this.bottomOpacity;
        },

        classForContainer() {
            let cl : any = {fixed: this.bottomOpacity == 0}

            if (this.bottomOpacity == 0) {
                cl['progress_' + 100] = true;
            }

            if (this.bottomOpacity <= 0.25) {
                cl['progress_' + 75] = true;
            }

            if (this.bottomOpacity <= 0.5) {
                cl['progress_' + 50] = true;
            }

            if (this.bottomOpacity <= 0.75) {
                cl['progress_' + 25] = true;
            }

            return cl;
        }
        
    }

});